var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderTable"},[_c('reservation-modal',{attrs:{"data":_vm.reservationModalData},model:{value:(_vm.showReservationModal),callback:function ($$v) {_vm.showReservationModal=$$v},expression:"showReservationModal"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"720","fixed-header":"","headers":_vm.headers,"items":_vm.content,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"header-props":{ sortIcon: null }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"margin-right":"5px","text-align":"center","vertical-align":"middle"},attrs:{"src":require(("@/assets/icons/" + (item.provider.toLowerCase()) + ".png")),"height":"16px","width":"16px"}})]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.getDate(item.creationTime)))]),_c('br')]}},{key:"item.orderTotalNetAmount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.orderTotalNetAmount)+" "+_vm._s(item.orderCurrency)+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"medium":""},on:{"click":function($event){_vm.showReservationModal=true, _vm.reservationModalData=item}}},[_vm._v("mdi-magnify")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }