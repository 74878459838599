<template>
  <!-- GLOBAL OPTIONS -->

  <div class="options" v-if="data != null">
    <h1 class="overline">Opcje globalne</h1>
    <v-divider />
    <v-container class="mx-3 mt-2" fill-height>
      <v-form v-for="property in data" :key="property.id">
        <div class="d-flex" v-if="property.propertyName == 'MARGIN_PERCENTAGE'">
          <v-text-field
            dense
            outlined
            type="number"
            hint="Margin for product warnings"
            suffix="%"
            min="0"
            max="100"
            v-model="property.value"
            :label="property.propertyName"
            required
          ></v-text-field>
          <v-btn
            class="ml-3"
            depressed
            outlined
            x-large
            height="39.5px"
            color="rgba(0, 0, 0, 0.6)"
            @click="submitProperty(property.id, property.value)"
            >ZAPISZ</v-btn
          >
        </div>
      </v-form>
    </v-container>
    <v-container class="mx-3">
      <v-form v-for="property in data" :key="property.id">
        <div class="d-flex" v-if="property.propertyName == 'INGRAM_ORDER_SYNC'">
          <v-switch
            hint="Ingram order sync"
            v-model="property.value"
            false-value="0"
            true-value="1"
            :label="property.description"
          >
          </v-switch>
          <v-btn
            class="ml-3"
            depressed
            outlined
            x-large
            height="39.5px"
            color="rgba(0, 0, 0, 0.6)"
            @click="submitProperty(property.id, property.value)"
            >ZAPISZ</v-btn
          >
        </div>
      </v-form>
    </v-container>

    <!-- TRELLO LABELS -->

    <h1 class="overline">Etykiety Trello</h1>
    <v-divider />
    <v-container class="mx-4 mt-2" fill-height v-if="trelloLabels != null">
      <v-row>
        <v-col
          cols="12"
          sm="2"
          md="2"
          lg="2"
          v-for="label in trelloLabels"
          :key="label.id"
        >
          <v-container>
            <div class="d-flex" v-if="availableTrelloLabels != null">
              <v-autocomplete
                :value="label.name"
                :label="label.staticName"
                dense
                class="ml-3"
                depressed
                outlined
                :items="availableTrelloLabels"
                item-text="name"
                return-object
                @input="updateTrelloLabelsArray(label.id, $event)"
              >
              </v-autocomplete>
            </div>
          </v-container>
        </v-col>
      </v-row>
      <v-container>
        <div class="d-flex">
          <v-btn
            depressed
            outlined
            x-large
            height="39.5px"
            color="rgba(0, 0, 0, 0.6)"
            @click="updateTrelloLabels()"
            >Aktualizuj etykiety</v-btn
          >
        </div>
      </v-container>
    </v-container>

    <!-- ACOUNT DETAILS -->

    <h1 class="overline">Szczegóły konta</h1>
    <v-divider />

    <v-container class="mx-4 mt-2" v-if="userDetails != null">
      <v-row>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Nazwa użytkownika"
            v-model="userDetails.username"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Imię"
            v-model="userDetails.name"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Nazwisko"
            v-model="userDetails.surname"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Stanowisko"
            v-model="userDetails.businessTitle"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Adres email"
            v-model="userDetails.email"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            dense
            outlined
            disabled
            label="Numer telefonu"
            v-model="userDetails.phone"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox
            dense
            disabled
            label="Uwierzytelnianie dwuskładnikowe (2FA)"
            v-model="userDetails.using2FA"
          ></v-checkbox>
        </v-col>
      </v-row>
    </v-container>

    <!-- CHANGE PASSWORD -->

    <h1 class="overline">Opcje konta</h1>
    <v-divider />
    <v-form>
      <v-container class="mx-4 mt-2">
        <div class="d-flex">
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                v-model="oldPassword"
                :type="show1 ? 'text' : 'password'"
                hint="Wprowadź swoje stare hasło"
                autocomplete="off"
                label="Stare hasło"
                required
                @click:append="show1 = !show1"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                v-model="newPassword"
                autocomplete="off"
                hint="Wprowadź nowe hasło"
                :rules="[rules.min]"
                label="Nowe hasło"
                required
                :type="show2 ? 'text' : 'password'"
                @click:append="show2 = !show2"
                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-btn
                depressed
                outlined
                x-large
                height="39.5px"
                color="rgba(0, 0, 0, 0.6)"
                @click="changePassword()"
                >Zmień hasło</v-btn
              >
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-form>

    <v-container class="mx-4 mt-2 mb-6" v-if="userDetails.using2FA == false">
      <div class="d-flex">
        <v-row>
          <v-col>
            <v-btn
              depressed
              outlined
              x-large
              height="39.5px"
              color="rgba(0, 0, 0, 0.6)"
              @click="enable2fa()"
              >Włącz uwierzytelnianie dwuskładnikowe (2FA)</v-btn
            >
          </v-col>
        </v-row>
      </div>
    </v-container>

    <v-dialog v-model="showfa2" width="700px" persistent>
      <v-card class="pa-4">
        <v-alert color="success" icon="$success" title="Alert title"
          >Pomyślnie włączono uwierzytelnianie dwuskładnikowe, zeskanuj poniższy
          kod QR w aplikacji Authenticator:</v-alert
        >
        <v-img v-if="fa2url != null" :width="300" :src="fa2url" />
        <v-btn @click="showfa2 = false">Kliknij aby potwierdzić</v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Options",
  methods: {
    updateTrelloLabelsArray(objectId, trelloLabelObject) {
      this.trelloLabels.forEach((element) => {
        if (element.id == objectId) {
          element.name = trelloLabelObject.name;
          element.trelloId = trelloLabelObject.id;
          console.log(this.trelloLabels);
        }
      });
    },
    updateTrelloLabels() {
      this.$store
        .dispatch("updateTrelloLabels", this.trelloLabels)
        .then((response) => {
          if (response.data.sucess) {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "success",
              text: "Updated trello labels",
            });
          } else {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "success",
              text: "Refresh page",
            });
            // this.$store.dispatch("snackbar/setSnackbar", {
            //   color: "error",
            //   text: "Something went wrong with updateTrelloLabels()"
            // });
          }
        });
    },
    getTrelloLabels() {
      this.$store
        .dispatch("getTrelloLabels")
        .then((response) => {
          this.trelloLabels = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getTrelloLabels()",
          });
        });
    },
    getAvailableLabels() {
      this.$store
        .dispatch("getAvailableLabels")
        .then((response) => {
          this.availableTrelloLabels = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getAvailableLabels()",
          });
        });
    },
    submitProperty(id, value) {
      this.$store
        .dispatch("changeProperty", {
          id: id,
          value: value,
        })
        .then((response) => {
          this.content = response.data.content;
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Done",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with submitProperty()",
          });
        });
    },
    changePassword() {
      this.$store
        .dispatch("changePassword", {
          newPassword: this.newPassword,
          oldPassword: this.oldPassword,
        })
        .then((response) => {
          if (response.data.sucess) {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "success",
              text: "Password changed",
            });
          } else {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Incorrect password",
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with changePassword()",
          });
        });
    },
    enable2fa() {
      this.$store
        .dispatch("enable2fa")
        .then((response) => {
          this.showfa2 = true;
          this.fa2url = response.data;
          this.userDetails.using2FA = true;
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Włączono uwierzyelnianie dwuskładnikowe",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with changePassword()",
          });
        });
    },
    getAllProperties() {
      this.$store
        .dispatch("getAllProperties", {})
        .then((response) => {
          this.data = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getAllProperties()",
          });
        });
    },
    getUserDetails() {
      this.$store
        .dispatch("getUserDetails", {})
        .then((response) => {
          this.userDetails = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getUserDetails()",
          });
        });
    },
  },
  data() {
    return {
      trelloLabels: null,
      availableTrelloLabels: null,
      data: null,
      show1: false,
      show2: false,
      userDetails: null,
      newPassword: null,
      oldPassword: null,
      rules: { min: (v) => v.length >= 6 || "Minimum 6 characters" },
      ex3: { label: "thumb-color", val: 50, color: "grey" },
      fa2url: null,
      showfa2: false,
    };
  },
  mounted() {
    this.getAllProperties();
    this.getUserDetails();
    this.getTrelloLabels();
    this.getAvailableLabels();
  },
  computed: {
    readableValue() {
      return this.value + "%";
    },
  },
};
</script>
