<template>
  <v-dialog v-model="show" width="1400px" v-if="data!=null">
    <v-card class="pa-4">
        <v-form>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Dane źródłowe wysyłającego przesyłkę</div>
                    </v-col>
                  </v-row>  
                  <v-col>
                    <div><b>Nazwa:</b> INS Instruments Marcin Jastrząb</div>
                    <div><b>Miasto:</b> Kraków</div>
                    <div><b>Ulica:</b> Oleandry 2</div>
                    <div><b>Kod pocztowy:</b> 30-063</div>
                    <div><b>Osoba kontaktowa:</b> Marcin Jastrząb</div>
                    <div><b>Telefon:</b> 694026011</div>
                </v-col>
                </v-col>
                <v-col cols="12" md="4">
                <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Dane źródłowe odbiorcy wysyłki</div>
                    </v-col>
                </v-row> 
                <v-col>
                  <div v-if="data.billingAddress.clientType=='COMPANY' && data.shippingAddress.companyName!=''"><b>Nazwa:</b> {{data.shippingAddress.companyName}}</div>
                  <div v-if="data.billingAddress.clientType=='NATURAL_PERSON' || data.shippingAddress.companyName==''"><b>Nazwa:</b> {{data.shippingAddress.contactPerson}}</div>
                  <div><b>Miasto:</b> {{data.shippingAddress.city}}</div>
                  <div><b>Ulica:</b> {{data.shippingAddress.street}}</div>
                  <div><b>Kod pocztowy:</b> {{data.shippingAddress.postalCode}}</div>
                  <div><b>Osoba kontaktowa:</b> {{data.shippingAddress.contactPerson}}</div>
                  <div><b>Telefon:</b> {{data.shippingAddress.phoneNumber}}</div>
                </v-col>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Informacje źródłowe o zamówieniu</div>
                      <div><b>Wartość zamówienia:</b> {{data.orderValue - data.shippingCost}} PLN</div>
                      <div><b>Koszt wysyłki:</b> {{data.shippingCost}} PLN</div>
                      <div><b>Łączna wartość:</b> {{data.orderValue}} PLN</div>
                      <div><b>Zamówionych sztuk produktów:</b> {{this.countProducts(data.positions)}} szt.</div>
                    </v-col>
                </v-row> 
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Dane wysyłającego przesyłkę</div>
                    </v-col>
                  </v-row>
                  <v-text-field
                    dense
                    :counter="60"
                    v-model="shipperName"
                    label="Nazwa"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    :counter="20"
                    v-model="shipperCity"
                    label="Miasto"
                    outlined
                    required
                  ></v-text-field>
                  <v-row>
                    <v-col md="8">
                      <v-text-field
                        dense
                        :counter="35"
                        v-model="shipperStreet"
                        label="Ulica"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        dense
                        :counter="10"
                        v-model="shipperHouseNumber"
                        label="Numer domu"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        dense
                        :counter="10"
                        v-model="shipperApartamentNumber"
                        label="Numer lokalu"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    dense
                    :counter="6"
                    v-model="shipperPostalCode"
                    label="Kod pocztowy"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    :counter="60"
                    v-model="shipperContactPerson"
                    label="Osoba kontaktowa"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    :counter="9"
                    v-model="shipperPhone"
                    label="Telefon"
                    outlined
                    required
                  ></v-text-field>

                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Dane odbiorcy wysyłki</div>
                    </v-col>
                  </v-row>
                  <v-text-field
                    dense
                    :counter="60"
                    v-model="receiverName"
                    label="Nazwa"
                    outlined
                    required
                  ></v-text-field>
                
                  <v-text-field
                    dense
                    :counter="20"
                    v-model="receiverCity"
                    label="Miasto"
                    outlined
                    required
                  ></v-text-field>
                  <v-row>
                    <v-col md="8">
                      <v-text-field 
                        :background-color="this.failedStreetParse == true ? '#db5d46' : 'white'"
                        dense
                        :counter="35"
                        v-model="receiverStreet"
                        label="Ulica"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        dense
                        :counter="10"
                        :background-color="this.failedStreetParse == true ? '#db5d46' : 'white'"
                        v-model="receiverHouseNumber"
                        label="Numer domu"
                        outlined
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col md="2">
                      <v-text-field
                        dense
                        :counter="10"
                        :background-color="this.failedStreetParse == true ? '#db5d46' : 'white'"
                        v-model="receiverApartamentNumber"
                        label="Numer lokalu"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    dense
                    :counter="6"
                    v-model="receiverPostalCode"
                    label="Kod pocztowy"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    :counter="60"
                    v-model="receiverContactPerson"
                    label="Osoba kontaktowa"
                    outlined
                    required
                  ></v-text-field>
                  <v-text-field
                    dense
                    :counter="9"
                    v-model="receiverPhone"
                    label="Telefon"
                    outlined
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Informacje o przesyłce</div>
                    </v-col>
                  </v-row>
                      <v-select
                        :items="couriers"
                        label="Firma kurierska"
                        outlined
                        v-model="shippingCompany"
                        dense
                      ></v-select>
                      <v-text-field
                      dense
                      label="Notatka dla kuriera"
                      outlined
                      v-model="note"
                      required
                      ></v-text-field>
                      <v-text-field
                      dense
                      label="Zawartość"
                      outlined
                      v-model="contents"
                      required
                      ></v-text-field>
                      <v-row>
                        <v-col cols="12" md="3">
                          <v-checkbox
                            dense
                            v-model="cod"
                            :label="`Pobranie`"
                          ></v-checkbox>
                        </v-col>
                        <v-col cols="12" md="9">
                          <v-text-field v-if="cod==true"
                            dense
                            label="Wartość pobrania"
                            v-model="codValue"
                            :value="data.orderValue"
                            outlined
                            required
                          ></v-text-field>
                        </v-col>
                      </v-row>
                      <v-row>
                    <v-col cols="12" md="12">
                      <div class="overline">Paczki w przesyłce</div>
                    </v-col>
                  </v-row>
                  <v-row v-for="piece in this.pieces" :key="piece.id">
                    <v-col cols="12" md="2">
                      <v-text-field :id="piece.id+'-piece-weight'"
                      dense
                      label="kg"
                      outlined
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field :id="piece.id+'-piece-length'"
                      dense
                      label="X"
                      outlined
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field :id="piece.id+'-piece-width'"
                      dense
                      label="Y"
                      outlined
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="2">
                      <v-text-field :id="piece.id+'-piece-height'"
                      dense
                      label="Z"
                      outlined
                      required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="4">
                      <v-text-field :id="piece.id+'-piece-insuredValue'"
                      dense
                      label="ubezpieczenie"
                      outlined
                      required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="12" class="d-flex flex-row-reverse">
                      <v-btn x-small v-on:click="addPackage()">Dodaj paczkę</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="12" class="d-flex flex-row-reverse">
                  <v-btn x-small v-on:click="send()">Wyślij</v-btn>                
                </v-col>
              </v-row>
            </v-container>
          </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  watch: {
    data(newVal) {
      this.data = newVal;
      this.setData();
      this.isCod();
    }
  },
  mounted() {
    this.pieces = [{id: '0', weight: '', length: '', width: '', height: '', insuredValue: ''}];
  },
  methods: {
    setData(){
      if(this.data.billingAddress.clientType=='COMPANY' && this.data.shippingAddress.companyName!=''){
        this.receiverName=this.data.shippingAddress.companyName;
      } else if (this.data.billingAddress.clientType=='NATURAL_PERSON' || this.data.shippingAddress.companyName==''){
        this.receiverName=this.data.shippingAddress.contactPerson;
      }
      this.receiverCity = this.data.shippingAddress.city;
      this.receiverStreet = this.getStreetName(this.data.shippingAddress.street);
      this.receiverHouseNumber = this.getStreetNumber(this.data.shippingAddress.street);
      this.receiverApartamentNumber = this.getApartamentNumber(this.data.shippingAddress.street);
      this.receiverPostalCode = this.data.shippingAddress.postalCode;
      this.receiverContactPerson = this.data.shippingAddress.contactPerson;
      this.receiverPhone = this.data.shippingAddress.phoneNumber;
      this.codValue = this.data.orderValue;
    },

    send(){
      var requestData = {};
      requestData['shippingProvider'] = this.shippingCompany;
      requestData['shipperName'] = this.shipperName;
      requestData['shipperCity'] = this.shipperCity;
      requestData['shipperStreet'] = this.shipperStreet;
      requestData['shipperHouseNumber'] = this.shipperHouseNumber;
      requestData['shipperApartamentNumber'] = this.shipperApartamentNumber;
      requestData['shipperPostalCode'] = this.shipperPostalCode;
      requestData['shipperContactPerson'] = this.shipperContactPerson;
      requestData['shipperPhone'] = this.shipperPhone;
      requestData['receiverName'] = this.receiverName;
      requestData['receiverCity'] = this.receiverCity;
      requestData['receiverStreet'] = this.receiverStreet;
      requestData['receiverHouseNumber'] = this.receiverHouseNumber;
      requestData['receiverApartamentNumber'] = this.receiverApartamentNumber;
      requestData['receiverPostalCode'] = this.receiverPostalCode;
      requestData['receiverContactPerson'] = this.receiverContactPerson;
      requestData['receiverPhone'] = this.receiverPhone;
      requestData['content'] = this.contents;
      requestData['comment'] = this.note;
      requestData['isCod'] = this.cod;
      requestData['codValue'] = this.codValue;
      requestData['shippingPieces'] = this.getPieces();
      if(this.shippingCompany == ""){
        this.dispatchSnackbarError("Wybierz firmę kurierską!")
          return false;
      }
      if(this.shipperName == "" || this.shipperCity == "" || this.shipperStreet == "" || this.shipperHouseNumber == "" ||
        this.shipperPostalCode == "" || this.shipperContactPerso == "" || this.shipperPhone == "") {
          this.dispatchSnackbarError("Uzupełnij dane wysyłającego!")
          return false;
         }
      if(this.receiverName == "" || this.receiverCity == "" || this.receiverStreet == "" || this.receiverStreet == "" || this.receiverHouseNumber == "" || 
        this.receiverPostalCod == "" || this.receiverPostalCode == "" || this.receiverContactPerson == "" || this.receiverPhone == ""){
          this.dispatchSnackbarError("Uzupełnij dane odbiorcy!")
          return false;
         }
      if(this.contents == ""){
        this.dispatchSnackbarError("Uzupełnij informacje o zawartości przesyłki!")
        return false;
      }
      this.getPieces().forEach( x => {
        if(x.weight == "" || x.length == "" || x.width == "" || x.height == ""){
          this.dispatchSnackbarError("Uzupełnij wszystkie wymiary przesyłki!")
          return false;
        }
        if(x.insuredValue == ""){
          this.dispatchSnackbarError("Wprowadź wartość ubezpieczenia dla każdej przesyłki!")
          return false;
        }
      })
      
        this.$store.dispatch("createShipmentFromOrder", {
            id: this.data.id,
            body: requestData,
        })
        .then((response) => {
          if(this.shippingCompany=="DHL"){
            window.open("data:application/pdf;base64, " + response.data[0]);
            Event.$emit('createShipment');
          } else if(this.shippingCompany=="UPS"){
            for(var i=0;i<response.data.length;i++){
              window.open("data:image/gif;base64, " + response.data[i])
              Event.$emit('createShipment');
            }
          }
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: error.response.data,
          });
        });
    },

    dispatchSnackbarError(comment) {
      this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: comment,
          });
    },

    countProducts(positions){
      var count = 0;
      positions.forEach(x => {
        count+=parseInt(x.units);
      })
      return count;
    },

    getPieces(){
      var shippingPieces = [];
      this.pieces.forEach(element => {
        var piece = {};
        piece["weight"] = document.getElementById(element.id+"-piece-weight").value;
        piece["width"] = document.getElementById(element.id+"-piece-width").value;
        piece["height"] = document.getElementById(element.id+"-piece-height").value;
        piece["length"] = document.getElementById(element.id+"-piece-length").value;
        piece["quantity"] = 1;
        piece["insuredValue"] = document.getElementById(element.id+"-piece-insuredValue").value
        shippingPieces.push(piece);
      });
      return shippingPieces;
    },

    addPackage(){
      this.pieces.push({id: this.pieces.length+1, weight: '', length: '', width: '', height: '', insuredValue: ''});
    },

    isCod(){
      if(this.data.deliveryMethod=="COURIER_COD"){
        this.cod=true;
      }
    },

    getApartamentNumber(fullStreet){
      var street = fullStreet.trim().replace(/[&#,+()$~%.'":*?<>{}]/g, '').match('^(.*[^0-9]+) (([1-9][0-9]*)/)?([1-9][0-9]*[a-cA-C]?)$');
      if(street == null){
        this.failedStreetParse=true;
        return "";
      }
      if(street.length>3 && street[3] != null) {
        return street[street.length-1];
      } else {
        return " ";
      }
    },

    getStreetNumber(fullStreet){
      var street = fullStreet.trim().replace(/[&#,+()$~%.'":*?<>{}]/g, '').match('^(.*[^0-9]+) (([1-9][0-9]*)/)?([1-9][0-9]*[a-cA-C]?)$');
      if(street == null){
        this.failedStreetParse=true;
        return "";
      }
      if(street.length>3 && street[3] != null) {
        return street[street.length-2];
      } else {
        return street[street.length-1];
      }
    },

    getStreetName(fullStreet){
      var street = fullStreet.trim().replace(/[&#,+()$~%.'":*?<>{}]/g, '').match('^(.*[^0-9]+) (([1-9][0-9]*)/)?([1-9][0-9]*[a-cA-C]?)$');
      if(street == null){
        this.failedStreetParse=true;
        return fullStreet;
      }
      return street[1];
    }
  },
  data() {
    return {
      refferer: null,
      pieces: [{id: '0', weight: '', length: '', width: '', height: '', insuredValue: ''}],
      couriers: ["DHL", "UPS"],
      cod: false,
      codValue: 0,
      note: "",
      contents: "elektronika",
      shippingCompany: "",
      failedStreetParse: false,
      shipperName: "INS Instruments Marcin Jastrząb",
      shipperCity: "Kraków",
      shipperStreet: "Oleandry",
      shipperHouseNumber: "2",
      shipperApartamentNumber: "",
      shipperPostalCode: "30-063",
      shipperContactPerson: "Marcin Jastrząb",
      shipperPhone: "694026011",
      receiverName: null,
      receiverCity: null,
      receiverStreet: null,
      receiverHouseNumber: null,
      receiverApartamentNumber: null,
      receiverPostalCode: null,
      receiverContactPerson: null,
      receiverPhone: null,
    };
  },
  props: {
    value: Boolean,
    data: null,
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
};
</script>