<template>
  <div class="options">
    <h1 class="overline">Shoper CSV Update</h1>
        <p>Poprawny dokument powinien posiadać conajmniej dwie kolumny, zgodnie z tabelą poniżej (delimeter to przecinek):</p>
        <div style="width:300px" class="my-2">
            <v-simple-table dense fixed-header="true">
                <thead>
                    <tr>
                    <th class="text-left">
                        producer_code
                    </th>
                    <th class="text-left">
                        gross_price
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td>PER250CM1</td>
                    <td>4800.00</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </div>
        <v-divider></v-divider>
        <h1 class="overline">Załaduj plik</h1>
        <div  style="width:300px" class="my-2">
            <v-file-input
                label="plik CSV"
                accept=".csv"
                outlined
                dense
                v-model="file"
            ></v-file-input>
            <v-row justify="end">
            <v-spacer></v-spacer>
            <v-btn v-on:click="sendCsvFile()">Wyślij</v-btn>
            </v-row>
        </div>
        <v-overlay :value="overlay">
          <div class="text-center">
            <div v-if="this.isDone == false">
              <v-progress-circular
                :size="150"
                indeterminate
                color="primary"
              ></v-progress-circular><br>
              AKTUALIZOWANIE PRODUKTÓW
            </div>
            <div v-if="this.isDone == true">
                <v-icon style="font-size: 115px;" dark @click="overlay = !overlay">
                  mdi-emoticon-excited-outline
                </v-icon><br>
                <div v-if="this.response!='No products to update'">
                  ZAKOŃCZONO, ZAKTUALIZOWANO PRODUKTY O ID:
                  <ul v-for="id in this.response" v-bind:key="id">
                    <li>{{id}}</li>
                  </ul>
                </div>
                <div v-if="this.response=='No products to update'">
                  ZAKOŃCZONO, NIE ZNALEZIONO PRODUKTÓW DO AKTUALIZACJI.
                </div>
            </div>
          </div>
        </v-overlay>
  </div>
</template>

<script>

export default {
  name: 'csvupdate',
  methods: {
      sendCsvFile(){
        this.overlay = true;
        this.$store.dispatch("postShoperUpdateCsvFile", {
            file: this.file,
        })
        .then((response) => {
          this.response = response.data;
          this.isDone = true;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: error.response.data,
          });
        });
      }
  },
  data () {
    return {
        file: null,
        overlay: false,
        isDone: false,
        response: null,
    }
  },

}
</script>