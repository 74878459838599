 <template>
  <div class="orderTable">
    <v-data-table
      dense
      height="720"
      fixed-header
      :headers="headers"
      :items="content"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :header-props="{ sortIcon: null }"
    >
        <template v-slot:item.creationTime="{ item }">
            <span class="text-center">{{ getDate(item.createdAt) }}</span>
        </template>
        <template v-slot:item.price="{ item }">
            {{item.price.toFixed(2)}} {{item.currency}}
        </template>
                <template v-slot:item.priceWithMargin="{ item }">
            {{item.priceWithMargin.toFixed(2)}} {{item.currency}}
        </template>
        <template v-slot:item.publicatedPrice="{ item }">
            {{item.publicatedPrice.toFixed(2)}} {{item.currency}}
        </template>
        <template v-slot:item.vendorId="{ item }">
            <a :href="`https://wfirma.pl/goods/view/${item.vendorId}`" target="_blank">{{item.vendorId}}</a>
        </template>
        <template v-slot:item.shoperProductId="{ item }">
            <a :href="`https://komputerydlafirm.pl/pl/p/o/${item.shoperProductId}`" target="_blank">{{item.shoperProductId}}</a>
        </template>
        <template v-slot:footer.page-text>
          <v-icon @click="evaluateProductWarningsOnDemand()" v-if="onDemandProgressPossible">mdi-refresh</v-icon>
              <v-progress-circular v-if="onDemandProgressLoading"
              indeterminate
              color="grey"
              ></v-progress-circular>
        </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  methods: {
    getDate(dateTime){
        let temp = dateTime.split(" ");
        return temp[0];
    },
    evaluateProductWarningsOnDemand() {
        this.onDemandProgressPossible=false;
        this.onDemandProgressLoading=true;
        this.$store.dispatch("evaluateProductWarningsOnDemand", {
        })
        .then((response) => {
          console.log(response.data.content);
          this.onDemandProgressPossible=true;
          this.onDemandProgressLoading=false;
          this.getProductsWarnings();
          this.$parent.refresh();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with evaluateProductWarningsOnDemand()",
          });
        });
    },
    getProductsWarnings() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = itemsPerPage * (page - 1);

      this.$store.dispatch("getProductsWarnings", {
          itemsPerPage: itemsPerPage,
          pageNumber: pageNumber,
          type: "PRICE_TOO_LOW",
        })
        .then((response) => {
          this.content = response.data.content;
          this.loading = false;
          this.content = response.data.content;
          this.totalItems = response.data.totalElements;
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getOrderPositions()",
          });
        });
    },
  },
  mounted() {
    this.getProductsWarnings();
  },

  watch: {
    options: {
      handler() {
        this.getProductsWarnings();
      },
    },
    deep: true,
  },
  
  name: "Orders",
  data() {
    return {
      onDemandProgressPossible: true,
      onDemandProgressLoading: false,
      totalItems: 0,
      search: "",
      modalVisible: false,
      modalData: null,
      showAllocatedOnly: false,
      loading: true,
      options: { page: 1 },
      content: [],
      footerProps: { "items-per-page-options": [20, 50, 100, 200, 300] },
      headers: [
        {
          id: "1",
          text: "Created at",
          align: "start",
          value: "creationTime",
          width: "100px",
        },
        {
          id: "2",
          text: "Wfirma ID",
          align: "start",
          sortable: false,
          value: "vendorId",
        },
        {
          id: "3",
          text: "Shoper ID",
          sortable: false,
          value: "shoperProductId",
        },
        { id: "4", text: "Latest highest buy price", value: "price" },
        { id: "5", text: "Minimal projected price with margin", value: "priceWithMargin" },
        { id: "6", text: "Publicated selling price", value: "publicatedPrice" },
        { id: "7", text: "Warning type", value: "warningType"}
      ],
    };
  },
};
</script>
