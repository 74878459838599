import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import ProductsTable from "../views/ProductsTable.vue";
import Product from "../views/Product.vue";
import Reservations from "../views/Reservations.vue";
import Orders from "../views/Orders.vue";
import Users from "../views/Users.vue";
import Options from "../views/Options.vue";
import Login from "../views/Login.vue";
import Logout from "../views/auth/Logout.vue";
import store from "../store";
import Warnings from "../views/Warnings.vue";
import Allegro from "../views/Allegro.vue";
import Shipments from "../views/Shipments.vue";
import CsvUpdate from "../views/CsvUpdate.vue";
import WfirmaReportGenerator from "../views/WfirmaReportGenerator.vue";
import Offers from "../views/Offers.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/productsTable",
    name: "productsTable",
    component: ProductsTable,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/reservations",
    name: "reservations",
    component: Reservations,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/options",
    name: "options",
    component: Options,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      requiresVisitor: true,
      requiresAuth: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: Logout,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/product/:id",
    name: "product",
    params: "item",
    component: Product,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/warnings",
    name: "warnings",
    component: Warnings,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/allegro",
    name: "allegro",
    component: Allegro,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: Orders,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/shipments",
    name: "shipments",
    component: Shipments,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/csvupdate",
    name: "csvupdate",
    component: CsvUpdate,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/wfirmareportgenerator",
    name: "wfirmareportgenerator",
    component: WfirmaReportGenerator,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/offers",
    name: "offers",
    component: Offers,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    store
      .dispatch("authorizeToken", {}) // eslint-disable-next-line no-unused-vars
      .then((response) => {
        next();
      }) // eslint-disable-next-line no-unused-vars
      .catch((error) => {
        router.replace("/login");
        store.dispatch("destroyToken");
        store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Zaloguj się!",
        });
      });
  } else {
    next();
  }
});

export default router;
