<template>
    <nav>
        <v-app-bar clipped-left app class="elevation-0 grey lighten-4">
            <v-app-bar-nav-icon v-if="loggedIn" text @click="drawer = !drawer"></v-app-bar-nav-icon >
            <v-toolbar-title>
                <span class="font-weight-light">RW</span>
                <span>Central</span>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn v-if="loggedIn" text color="grey">
                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'logout' }">
                    <span>Wyloguj</span>
                    <v-icon right>mdi-exit-to-app</v-icon>
                </router-link>
            </v-btn>
            <v-btn v-if="!loggedIn" text color="grey">
                <router-link style="text-decoration: none; color: inherit;" :to="{ name: 'login' }">
                    <span>Zaloguj</span>
                    <v-icon right>mdi-exit-to-app</v-icon>
                </router-link>
            </v-btn>
        </v-app-bar>
        <v-navigation-drawer class="grey lighten-4" permanent expand-on-hover clipped v-if="loggedIn" app v-model="drawer">
            <v-list nav dense>
                <v-list-item v-for="link in links" :key="link.text" router :to="link.route">
                    <v-list-item-icon>
                        <v-icon>{{link.icon}}</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{link.text}}</v-list-item-title>
                </v-list-item>

                <v-list-group :value="false" prepend-icon="mdi-hammer-screwdriver" color="gray" append-icon="">
                    <template v-slot:activator>
                        <v-list-item-title>{{nestedLink.name}}</v-list-item-title>
                    </template>
                    <v-list-item v-for="link in nestedLink.links" :key="link.text" router :to="link.route">
                        <v-list-item-icon>
                            <v-icon>{{link.icon}}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-title>{{link.text}}</v-list-item-title>
                    </v-list-item>

                </v-list-group>


            
            </v-list>
        </v-navigation-drawer>
    </nav>
</template>

<script>
export default {
    computed: {
        loggedIn(){
            return this.$store.getters.loggedIn
        }
    },
    data() {
        return {
            drawer: false,
            links: [
                { icon: 'mdi-view-dashboard', text: 'Pulpit', route: '/' },
                { icon: 'mdi-folder-outline', text: 'Produkty', route: '/productsTable' },
                { icon: 'mdi-format-list-checkbox', text: 'Rezerwacje', route: '/reservations' },
                { icon: 'mdi-clipboard-list-outline', text: 'Oferty', route: '/offers' },
                { icon: 'mdi-alert-circle-outline', text: 'Ostrzeżenia', route: '/warnings' },
                { icon: 'mdi-basket-outline', text: 'Zamówienia', route: '/orders' },
                { icon: 'mdi-truck-outline', text: 'Przesyłki', route: '/shipments' },
                { icon: 'mdi-tag', text: 'Allegro', route: '/allegro' },
                { icon: 'mdi-account-multiple', text: 'Użytkownicy', route: '/users' },
                { icon: 'mdi-cog-outline', text: 'Opcje', route: '/options' },
            ],
            nestedLink: {
                name: "Narzędzia",
                links: [ { icon: 'mdi-tag-arrow-up-outline', text: 'Akutalizacja CSV', route: '/csvupdate' },
                         { icon: 'mdi-folder-download-outline', text: 'Generator raportów', route: '/wfirmareportgenerator' } ]
            }
        }
    },
}
</script>
