var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderLineTable"},[_c('v-switch',{attrs:{"dense":"","align":"center","label":'Show allocated only'},on:{"click":function($event){return _vm.getOrderPositions()}},model:{value:(_vm.showAllocatedOnly),callback:function ($$v) {_vm.showAllocatedOnly=$$v},expression:"showAllocatedOnly"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"670","fixed-header":"","headers":_vm.headers,"items":_vm.content,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"header-props":{ sortIcon: null }},on:{"update:options":function($event){_vm.options=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getOrderPositions.apply(null, arguments)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.provider",fn:function(ref){
var item = ref.item;
return [_c('img',{staticStyle:{"margin-right":"5px","text-align":"center","vertical-align":"middle"},attrs:{"src":require(("@/assets/icons/" + (item.provider.toLowerCase()) + ".png")),"height":"16px","width":"16px"}})]}},{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.getDate(item.creationTime)))])]}},{key:"item.allocatedQtyFromStock",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor(item.allocatedQtyFromStock),"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.allocatedQtyFromStock)+" ")])]}},{key:"item.allocatedQtyInDelivery",fn:function(ref){
var item = ref.item;
return [(item.allocatedQtyInDelivery!=null)?_c('v-chip',{attrs:{"color":_vm.getColor1(item.allocatedQtyInDelivery),"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.allocatedQtyInDelivery)+" ")]):_vm._e(),(item.allocatedQtyInDelivery==null)?_c('v-chip',{attrs:{"disabled":"","outlined":"","small":""}},[_vm._v(" - ")]):_vm._e()]}},{key:"item.shipped",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":_vm.getColor1(item.shipped),"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.shipped)+" ")])]}},{key:"item.invoiced",fn:function(ref){
var item = ref.item;
return [(item.invoiced!=null)?_c('v-chip',{attrs:{"color":_vm.getColor2(item.invoiced),"outlined":"","small":""}},[_vm._v(" "+_vm._s(item.invoiced)+" ")]):_vm._e(),(item.invoiced==null)?_c('v-chip',{attrs:{"disabled":"","outlined":"","small":""}},[_vm._v(" - ")]):_vm._e()]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price)+" "+_vm._s(item.orderCurrency)+" ")]}},{key:"item.isEnabled",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"dense":""},on:{"change":function($event){return _vm.changeState(item.id, item.isEnabled)}},model:{value:(item.isEnabled),callback:function ($$v) {_vm.$set(item, "isEnabled", $$v)},expression:"item.isEnabled"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }