<template>
  <div class="options">
    <h1 class="overline">Generator raportów Wfirma</h1>
    <v-row cols="12">
      <v-col md="4">
        Wybierz typ raportu:
        <v-select
          class="mt-3"
          outlined
          :items="availableReports"
          v-model="selectedReport"
        ></v-select>
        Wybierz miesiąc raportu:
        <v-date-picker
          color="indigo"
          full-width
          v-model="date"
          type="month"
          class="mt-4"
          min="2020-01"
          locale="pl-pl"
          :max="getToDate()"
        ></v-date-picker>
        <v-btn
          class="my-2"
          dark
          block
          color="indigo"
          @click="overlay = !overlay"
          v-on:click="generateCsv()"
        >
          Rozpocznij generowanie raportu
        </v-btn>
      </v-col>

      <v-col md="8">
        <v-simple-table style="min-height: 528px" v-if="reportsList != null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Typ raportu</th>
                <th class="text-left">Data wygenerowania</th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in reportsList" :key="item.id">
                <td>{{ item.reportType }}</td>
                <td>{{ dateFormatter(item.createdAt) }}</td>
                <td>
                  <v-btn x-small @click="downloadCsv(item.id)">pobierz</v-btn>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row
          class="mt-2"
          align="center"
          v-if="totalPages > 1"
          justify="center"
        >
          <v-pagination
            v-model="currentPage"
            :length="totalPages"
            :total-visible="5"
          ></v-pagination>
        </v-row>
      </v-col>
    </v-row>
    <v-overlay :value="overlay">
      <div v-if="this.isFileReady == true">
        <v-icon
          style="font-size: 115px"
          dark
          @click="(overlay = !overlay), (isFileReady = false)"
        >
          mdi-check </v-icon
        ><br />
        Zlecono generację raportu!
      </div>
    </v-overlay>
  </div>
</template>

<script>
export default {
  name: "wfirmareportgenerator",
  methods: {
    dateFormatter(date) {
      let dateObj = new Date(date);
      return dateObj.toLocaleString();
    },

    getAvailableWfirmaReports() {
      this.$store
        .dispatch("getWfirmaAvailableReports", {})
        .then((response) => {
          this.availableReports = response.data;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Nie mozna pobrać dostępnych raportów " + error.data,
          });
        });
    },

    getReports(page) {
      let calculatedOffset = this.itemsPerPage * (page - 1);
      this.$store
        .dispatch("getReports", {
          limit: this.itemsPerPage,
          offset: calculatedOffset,
        })
        .then((response) => {
          this.reportsList = response.data.content;
          this.totalItems = response.data.totalElements;
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Nie mozna pobrać dostępnych raportów " + error.data,
          });
        });
    },

    generateCsv() {
      if (this.date == null) {
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Wybierz datę!",
        });
        this.overlay = false;
        return;
      }
      if (this.selectedReport == null) {
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Wybierz typ raportu!",
        });
        this.overlay = false;
        return;
      }
      this.$store
        .dispatch("getCsvWfirmaReport", {
          type: this.selectedReport,
          year: this.date.substring(0, 4),
          month: this.date.substring(5, 7),
        })
        .then((response) => {
          if (response.status == "409") {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Akcja jest obecnie wykonywana!",
            });
            this.overlay = !this.overlay;
            return;
          }
          this.data = response.data;
          this.isFileReady = true;
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: error.response.data,
          });
          this.overlay = false;
        });
    },

    downloadCsv(id) {
      this.$store
        .dispatch("getReportData", { id: id })
        .then((response) => {
          var hiddenElement = document.createElement("a");
          hiddenElement.href =
            "data:text/csv;charset=utf-8," +
            encodeURI(response.data.replace("#", ""));
          hiddenElement.target = "_blank";
          hiddenElement.download = "report_data.csv";
          hiddenElement.click();
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Nie udało się pobrać raportu " + error.data,
          });
        });
    },

    getToDate() {
      var today = new Date();
      var year = today.getFullYear();
      var month = today.getMonth() + 1;
      if (month <= 9) {
        month = 0 + "" + month;
      }
      return year + "-" + month;
    },
  },
  data() {
    return {
      overlay: false,
      date: null,
      isFileReady: false,
      data: null,
      availableReports: null,
      selectedReport: null,
      reportsList: null,
      itemsPerPage: 10,
      page: 1,
      totalPages: 1,
    };
  },
  computed: {
    currentPage: {
      totalPages() {
        return this.content.totalPages;
      },
      get() {
        return parseInt(this.$route.query.page || 1);
      },
      set(newPage) {
        this.$router
          .push({ query: { ...this.$route.query, page: newPage } })
          .catch(() => {});
        this.getReports(newPage);
      },
    },
  },
  mounted() {
    this.getAvailableWfirmaReports();
    this.getReports(1);
  },
};
</script>
