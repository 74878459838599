<template>
    <div class="mt-3">

    <shipping-modal v-model="showShippingModal" :data="currentShippingData" />
    <shipping-status-modal v-model="showShippingStatusModal" :data="this.shipmentStatusData" :trackingNumber="this.statusTrackingNumber"/>
        <v-row class="ms-2 mt-2" md="12">
          <v-col cols="12" md="12">
            <div class="overline">Akcje:</div>
            <v-tooltip right><template v-slot:activator="{ on, attrs }"><v-btn v-bind="attrs" v-on="on" fab class="mx-1" elevation="0" v-on:click="requestRwcOrderUpdate()"><v-icon>mdi-download</v-icon></v-btn></template><span style="font-size: 8px">Pobierz zamówienia</span></v-tooltip>
          </v-col>
        </v-row>
        <v-row md="12">
          <v-col cols="1" md="1">
            <div style="width: 80px">
              <v-select
              :items="items"
              v-model="itemsPerPage"
              outlined
              dense
              hide-details="true"
              @change="getOrders(page)"
              ></v-select>
            </div>
          </v-col>
          <v-col cols="9" md="9"></v-col>
          <v-col cols="2" md="2">
            <v-text-field
              outlined
              disabled
              dense
              label="Wyszukiwana fraza"
              hide-details="true"
            ></v-text-field>
          </v-col>
        </v-row>
        <div style="height: 10px;">
        <v-progress-linear v-if="loading == true"
          indeterminate
          color="black"
        ></v-progress-linear>
        </div>
        <v-row>
          <v-col cols="12" md="12">
          <v-expansion-panels accordion flat hover="true">
          <v-expansion-panel v-for="item in content" v-bind:key="item.id">
              <v-expansion-panel-header>
                <v-row>
                  <div style="width: 85px; margin:5px; text-align:center; border:1px solid;">
                    <div style="font-size: 14px; margin-bottom: 3px; width:100%; border-bottom:1px solid;">{{item.orderDatetime.substring(0,10)}}</div>
                    <div style="font-size: 14px; margin-bottom: 3px;">{{item.orderDatetime.substring(11,21)}}</div>
                  </div>
                  <div>
                    <div style="padding:5px; font-size:11px; text-align:left;">
                    <div style="font-weight: 600; margin-bottom: 3px; font-size:13px;">
                      <div v-if="item.shippingAddress!=null && item.billingAddress.companyName!=null" style="font-weight: 600; margin-bottom: 3px; font-size:13px; display: inline;">{{item.billingAddress.companyName}}</div>
                      <div v-if="item.shippingAddress!=null && item.billingAddress.companyName==null" style="font-weight: 600; margin-bottom: 3px; font-size:13px; display: inline;">{{item.billingAddress.contactPerson}}</div>
                      <div v-if="item.shippingAddress!=null" style="display: inline;"> {{item.billingAddress.contactPerson}}</div>
                      <div v-if="item.shippingAddress==null" style="display: inline;">{{item.email}}</div></div>
                      {{ item.positions && item.positions.length > 0 ? item.positions[0].productName : 'N/A' }}
                    </div>
                  </div>
                </v-row>
                  <template v-slot:actions>
                    <div v-if="item.orderSource=='SHOPER'" style="font-size:14px; text-align:right; padding-right: 10px">{{item.externalOrderNumber}}</div>
                      <a target="_blank" :href="'https://sklep193475.shoparena.pl/admin/orders/view/id/'+item.externalOrderNumber"><img v-if="item.orderSource=='SHOPER'" src="@/assets/icons/shoper.png" height="16px"></a>
                      <a target="_blank" :href="'https://allegro.pl/moje-allegro/sprzedaz/zamowienia/'+item.externalOrderNumber"><img v-if="item.orderSource=='ALLEGRO'" src="@/assets/icons/allegro.png" height="16px"></a>
                  </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="my-5">
                  <v-row no-gutters style="background-color:white">
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dane do wysyłki:</h1>
                          <div class="caption" v-if="item.shippingAddress!=null">
                              <div v-if="item.deliveryMethod!='PICKUP_POINT'">{{item.shippingAddress.companyName}}</div>
                              <div v-if="item.deliveryMethod=='PICKUP_POINT'">{{item.shippingAddress.optionalPickupPointName}}</div>
                              <div>{{item.shippingAddress.street}}</div>
                              <div>{{item.shippingAddress.postalCode}} {{item.shippingAddress.city}}</div>
                              <div>{{item.shippingAddress.contactPerson}}</div>
                              <div>{{item.shippingAddress.phoneNumber}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dane do faktury:</h1>
                          <div class="caption" v-if="item.billingAddress!=null">
                            <div v-if="item.billingAddress.taxNumber==null">{{item.billingAddress.contactPerson}}</div>
                              <div>{{item.billingAddress.companyName}}</div>
                              <div>{{item.billingAddress.street}}</div>
                              <div>{{item.billingAddress.postalCode}} {{item.billingAddress.city}}</div>
                              <div v-if="item.billingAddress.taxNumber!=null">NIP: {{item.billingAddress.taxNumber}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Informacje o kupującym</h1>
                          <div class="caption">
                              <div v-if="item.shippingAddress!=null">{{item.shippingAddress.contactPerson}}</div>
                              <div>{{item.email}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dodatkowe informacje:</h1>
                          <div class="caption">
                              <div v-if="item.wfirmaReservationNumber!=null">Rezerwacja: <a target="_blank" :href="'https://wfirma.pl/warehouse_documents/view/'+item.wfirmaReservationId">{{item.wfirmaReservationNumber}}</a></div>
                              <div>Data zamówienia: {{item.orderDatetime}}</div>
                              <div v-bind:style= "[item.paymentStatus=='UNPAID' ? {'color':'red'} : {'color':'black'}]">Status płatności: {{item.paymentStatus}}</div>
                              <div>Typ płatności: {{item.paymentType}}</div>
                              <div>Typ dostawy: {{item.deliveryMethod}}</div>
                              <div v-if="item.clientMessage!=''"><b>Wiadomość od kupującego:</b></div>
                              <div v-if="item.clientMessage!=''">{{item.clientMessage}}</div>
                          </div>
                      </v-col>
                  </v-row>
              


                  <h1 class="overline mt-4">Produkty w zamówieniu:</h1>
                  <v-row no-gutters class="mb-4">
                      <v-col cols="12" sm="12">
                      <v-simple-table dense>
                          <template v-slot:default>
                          <thead>
                              <tr>
                              <th class="text-left">
                                  Nazwa produktu
                              </th>
                              <th class="text-left">
                                  WFID
                              </th>
                              <th class="text-left">
                                  Cena
                              </th>
                              <th class="text-left">
                                  Ilość
                              </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="position in item.positions" v-bind:key="position.id">
                              <td>{{position.productName}}
                                <div v-if="position.configurationDetails!=null" style="font-size:10px">{{position.configurationDetails}}</div>
                              </td>
                              <td><a target="_blank" :href="'https://wfirma.pl/goods/view/'+position.warehouseCode">{{position.warehouseCode}}</a></td>
                              <td>{{position.unitPrice}} PLN</td>
                              <td>{{position.units}} szt.</td>
                              </tr>
                          </tbody>
                          </template>
                      </v-simple-table>
                      </v-col>
                  </v-row>


                <h1 class="overline mt-6" v-if="item.shippingInfo.length!=0">Przesyłki:</h1>
                  <v-row no-gutters class="mb-4" v-if="item.shippingInfo.length!=0">
                      <v-col cols="12" sm="12">
                        <div v-for="shipping in item.shippingInfo" v-bind:key="shipping.id">
                          {{shipping.shippingProvider}} {{shipping.trackingNumber}}
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small @click="getLabel(shipping.id, shipping.shippingProvider)">mdi-download</v-icon>
                              </span>
                            </template>
                            <span>Download label</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small @click="getShipmentStatus(shipping.trackingNumber, shipping.shippingProvider)">mdi-magnify</v-icon>
                              </span>
                            </template>
                            <span>View shipment status</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small @click="removeShipment(shipping.trackingNumber, shipping.shippingProvider)">mdi-delete-outline</v-icon>
                              </span>
                            </template>
                            <span>Remove shipment</span>
                        </v-tooltip>
                        </div>
                      </v-col>
                  </v-row>
                  
                  <v-row no-gutters>
                      <v-col cols="12" sm="2" class="text-left">
                          <h1 class="overline mt-4">Notatka wewnętrzna:</h1>
                          <div class="caption">{{item.internalNote}}</div>
                      </v-col>
                      <v-col cols="12" sm="8"></v-col>
                      <v-col cols="12" sm="2" class="text-right">
                          <h1 class="overline mt-4">Wartość zamówienia:</h1>
                          <div class="caption">Wartość: {{item.orderValue-item.shippingCost}} PLN</div>
                          <div class="caption">Koszt wysyłki: {{item.shippingCost}} PLN</div>
                          <div class="caption">Łączna wartość: {{item.orderValue}} PLN</div>
                      </v-col>
                  </v-row>

                  <v-row no-gutters class="text-right">
                      <v-col cols="12" sm="12">
                          <v-btn x-small outlined class="mr-1" v-on:click="generateReservation(item.id)">
                              <v-icon left v-if="item.wfirmaReservationNumber!=null">
                                mdi-check-circle-outline
                              </v-icon>
                              Generuj rezerwację
                          </v-btn>
                          <v-btn x-small outlined class="mr-1" v-on:click="generateTrelloCard(item.id)">
                              <v-icon left v-if="item.trelloCardGenerated==true">
                                mdi-check-circle-outline
                              </v-icon>
                              Eksportuj do Trello
                          </v-btn>
                          <v-btn x-small outlined @click.stop="generateShipping(item)">
                              <v-icon left v-if="item.shippingInfo.length>0">
                                mdi-check-circle-outline
                              </v-icon>
                              Generuj list przewozowy
                          </v-btn>
                      </v-col>
                  </v-row>

              </v-expansion-panel-content>
          </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>


    <v-row align="center" v-if='totalPages > 1' justify="center" class="mt-5">
          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7"></v-pagination>
    </v-row>
    </div>
</template>

<script>
import ShippingModal from '../components/ShippingModal.vue';
import ShippingStatusModal from '../components/ShippingStatusModal.vue';


export default {
  components: { ShippingModal, ShippingStatusModal },
  methods: {

    removeShipment(trackingNumber, shippingProvider){
      if(confirm("You are about to remove shipment no. "+trackingNumber+" provided by "+shippingProvider+". Are you sure?")){
        this.$store.dispatch("removeShipment", { trackingNumber: trackingNumber, shippingProvider: shippingProvider })
        .then((response) => {
            if(response.data == true){
              this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Succesfully removed shipment " + trackingNumber });
              this.getOrders(1);
            } else {
              this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Shipment removal failed" });
            }
        })
        .catch((error) => {
            console.log(error)
            this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with removeShipment()" });
        });
      }
    },

    getConfigArr(configurationDetails){
      return configurationDetails.split(';');
    },

    generateShipping(item){
      this.$store.dispatch("isShippingGenerated", { id: item.id })
        .then((response) => {
          if(response.data==false){
            this.currentShippingData=item;
            this.showShippingModal=true;
          } else {
            if(confirm("Shipment for this order has already been generated, do you want to proceed?")){
              this.currentShippingData=item;
              this.showShippingModal=true;
            }
          }

        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Shipment existence check failed" });
        });
    },


    doGenerateTrelloCard(id) {
      this.$store.dispatch("generateTrelloCard", {
        orderId: id,
      })
      .then((response) => {
        console.log(response);
        this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Created card in trello" });
      })
      .catch((error) => {
        console.log(error);
        this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Generating trello card failed" });
      });
    },

    generateTrelloCard(id) {
      this.$store.dispatch("isTrelloCardGenerated", { id: id })
        .then((response) => {
          if(response.data==false){
            this.doGenerateTrelloCard(id)
          } else {
            if(confirm("Trello card for this order has already been generated, do you want to proceed?")){
              this.doGenerateTrelloCard(id)
            }
          }

        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Trello card existence check failed" });
        });
    },

    doGenerateReservation(id) {
      this.$store
        .dispatch("generateWfirmaReservation", {
          orderId: id,
        })
        .then((response) => {
          this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Created reservation: "+response.data.number,});
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Try again, later"});
        });
    },

    generateReservation(id) {
      this.$store.dispatch("isReservationGenerated", { id: id })
        .then((response) => {
          if(response.data==false){
            this.doGenerateReservation(id)
          } else {
            if(confirm("Reservation for this order has already been generated, do you want to proceed?")){
              this.doGenerateReservation(id)
            }
          }

        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Wfirma reservation existence check failed" });
        });
    },

    requestRwcOrderUpdate() {
      this.$store
        .dispatch("requestRwcOrderUpdate")
        .then((response) => {
          console.log(response)
          this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Queued, will download new orders in 15 seconds" });
          setTimeout(()=>{this.getOrders()}, 15000);
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with requestRwcOrderUpdate()" });
        });
    },

    getOrders(page) {
      this.loading = true;
      let calculatedOffset = this.itemsPerPage * (page - 1);
      this.$store.dispatch("getRwcOrders", {
          limit: this.itemsPerPage,
          offset: calculatedOffset,
        })
        .then((response) => {
          this.content = response.data.content;
          this.totalItems = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with getOrders()" });
        });
    },
    
    getLabel(id, provider){
      this.$store
      .dispatch("getLabel", { id: id })
      .then((response) => {
          if(provider=="DHL"){
            window.open("data:application/pdf;base64, " + response.data);
          } else if(provider=="UPS"){
            window.open("data:image/gif;base64, " + response.data)
          }
      })
      .catch((error) => {
        console.log(error)
        this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with getLabel()" });
      });        
    },

    getShipmentStatus(trackingNumber, shippingProvider){
        this.$store
            .dispatch("getShipmentStatus", { trackingNumber: trackingNumber, shippingProvider: shippingProvider })
            .then((response) => {
                this.shipmentStatusData = response.data;
                this.statusTrackingNumber = trackingNumber;
                this.showShippingStatusModal = true;
            })
        .catch((error) => {
            console.log(error)
            this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with getShipmentStatus()" });
        });       
    },
  },
  data() {
    return {
        loading: false,
        content: null,
        showShippingModal: false,
        currentShippingData: null,
        showShippingStatusModal: false,
        shipmentStatusData: null,
        statusTrackingNumber: null,
        itemsPerPage: 10,
        page: 1,
        totalPages: 1,
        items: [5, 10, 20, 30],
    }
  },
  computed: {
    currentPage: {
      totalPages(){
        return this.content.totalPages;
      },
      get() {
        return parseInt(this.$route.query.page || 1)
      },
      set(newPage){
        this.$router.push({query: { ...this.$route.query, page: newPage}})
        .catch(() => {});
        this.getOrders(newPage);
      },
    }
  },
  mounted() {
      this.getOrders(1);
      Event.$on('createShipment', () => {
        this.getOrders(1);
      });
  },
};
</script>
