<template>
  <div class="dashboard">
    <shipping-modal v-model="showShippingModal" />
    <v-flex class="mt-4 mb-3">
      <div class="overline">Akcje:</div>
      <v-tooltip right
        ><template v-slot:activator="{ on, attrs }"
          ><v-btn
            v-bind="attrs"
            v-on="on"
            fab
            class="mx-1"
            elevation="0"
            v-on:click="synchronize()"
            ><v-icon>mdi-cloud-sync-outline</v-icon></v-btn
          ></template
        ><span style="font-size: 8px"
          >Synchronizacja Wfirma->Shoper</span
        ></v-tooltip
      >
      <v-tooltip right
        ><template v-slot:activator="{ on, attrs }"
          ><v-btn
            v-bind="attrs"
            v-on="on"
            fab
            class="mx-1"
            elevation="0"
            disabled
            ><v-icon>mdi-truck-outline</v-icon></v-btn
          ></template
        ><span style="font-size: 8px">Stwórz przesyłkę</span></v-tooltip
      >
      <v-tooltip right
        ><template v-slot:activator="{ on, attrs }"
          ><v-btn
            v-bind="attrs"
            v-on="on"
            fab
            class="mx-1"
            elevation="0"
            disabled
            ><v-icon>mdi-database-sync-outline</v-icon></v-btn
          ></template
        ><span style="font-size: 8px"
          >Pobierz zamówienia (Allegro+Shoper)</span
        ></v-tooltip
      >
    </v-flex>

    <v-row>
      <v-col cols="12" md="6">
        <div class="overline">Podsumowanie (30 dni)</div>
        <v-card class="pa-2 text-center" flat tile style="min-height: 400px">
          <v-row align="center">
            <v-col cols="12" md="4">
              <div style="font-size: small">przyjętych zamówień</div>
              <div style="font-size: x-large">
                {{ statistics.ordersAmount }}
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div style="font-size: small">o łącznej wartości</div>
              <div style="font-size: x-large">
                {{ statistics.ordersValue }} zł
              </div>
            </v-col>
            <v-col cols="12" md="4">
              <div style="font-size: small">zsynchronizowanych produktów</div>
              <div style="font-size: x-large">
                {{ statistics.productsCount }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>

      <v-col cols="12" md="6">
        <div class="overline">Kalendarz</div>
        <v-card class="pa-4" flat tile style="min-height: 400px">
          <v-toolbar flat>
            <v-btn
              outlined
              class="mr-4"
              color="grey darken-2"
              @click="setToday"
            >
              Dziś
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="prev">
              <v-icon small> mdi-chevron-left </v-icon>
            </v-btn>
            <v-btn fab text small color="grey darken-2" @click="next">
              <v-icon small> mdi-chevron-right </v-icon>
            </v-btn>
            <v-toolbar-title v-if="$refs.calendar">
              {{ $refs.calendar.title }}
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-menu bottom right>
              <template v-slot:activator="{ on, attrs }">
                <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
                  <span>{{ typeToLabel[type] }}</span>
                  <v-icon right> mdi-menu-down </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="type = 'month'">
                  <v-list-item-title>Miesiąc</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :type="type"
          ></v-calendar>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12">
        <div class="overline">Data ostatnich udanych synchronizacji</div>
        <v-card class="pa-2 text-center" flat tile style="min-height: 70px">
          <v-row>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/wfirma.png`)"
                height="25px"
                width="25px"
                title="Wfirma"
              />
              <div v-if="!lastSuccesses.wfirma">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.wfirma).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/shoper.png`)"
                height="25px"
                width="25px"
                title="Shoper"
              />
              <div v-if="!lastSuccesses.shoper">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.shoper).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/ingram.png`)"
                height="25px"
                width="25px"
                title="Ingram"
              />
              <div v-if="!lastSuccesses.ingram">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.ingram).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/also.png`)"
                height="25px"
                width="25px"
                title="ALSO"
              />
              <div v-if="!lastSuccesses.also">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.also).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/techdata.png`)"
                height="25px"
                width="25px"
                title="Techdata"
              />
              <div v-if="!lastSuccesses.techdata">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.techdata).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/ab.png`)"
                height="25px"
                width="25px"
                title="AB"
              />
              <div v-if="!lastSuccesses.ab">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.ab).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/action.png`)"
                height="25px"
                width="25px"
                title="Action"
              />
              <div v-if="!lastSuccesses.action">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.action).toLocaleString() }}
              </div>
            </v-col>
            <v-col cols="12" md="1">
              <img
                :src="require(`@/assets/icons/acc.png`)"
                height="25px"
                width="25px"
                title="ACC"
              />
              <div v-if="!lastSuccesses.acc">N/A</div>
              <div v-else>
                {{ new Date(lastSuccesses.acc).toLocaleString() }}
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <div class="overline mt-3">Podsumowanie synchronizacji</div>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="pa-2 text-center" flat tile>
          <v-simple-table dense>
            <thead>
              <tr>
                <th class="text-center">ID</th>
                <th class="text-center">Producent</th>
                <th class="text-center">Konsument</th>
                <th class="text-center">Czas wykonania</th>
                <th class="text-left">Podsumowanie</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in content" :key="item.id">
                <td class="text-center">{{ item.id }}</td>
                <td class="text-center">{{ item.actionProvider }}</td>
                <td class="text-center">{{ item.actionConsumer }}</td>
                <td class="text-center">{{ item.dateTime }}</td>
                <td class="text-left">
                  <div>
                    <ul>
                      <li
                        v-for="subitem in item.operationsWithinAction"
                        :key="subitem.id"
                      >
                        {{ subitem }}
                      </li>
                      <span v-if="item.operationsWithinAction == 0">N/A</span>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import ShippingModal from "../components/ShippingModal.vue";

export default {
  components: { ShippingModal },
  name: "Dashboard",
  data() {
    return {
      focus: "",
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      content: [],
      responseCode: 0,
      showShippingModal: false,
      statistics: [],
      lastSuccesses: [],
    };
  },
  methods: {
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },

    getStatisticsData() {
      this.$store
        .dispatch("getStatisticsData")
        .then((response) => {
          this.statistics = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getStatisticsData()",
          });
        });
    },

    getLastSuccesses() {
      this.$store
        .dispatch("getLastSuccesses")
        .then((response) => {
          console.log(response.data);
          this.lastSuccesses = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getLastSuccesses()",
          });
        });
    },

    synchronize() {
      axios
        .get(process.env.VUE_APP_ROOT_URL + "/action/updateShoperProducts", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token"),
          },
        })
        .then((response) => {
          this.displaySnackbar(response.status);
        })
        .catch((error) => {
          this.displaySnackbar(error.response.status);
        });
    },
    displaySnackbar(code) {
      if (code == "429") {
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Akcja w trakcie wykonywania",
        });
      } else if (code == "200") {
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "success",
          text: "Zlecono akcje",
        });
      } else {
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Cos poszło nie tak",
        });
      }
    },
    getActions() {
      this.$store
        .dispatch("getActions", { itemsPerPage: 10, pageNumber: 0 })
        .then((response) => {
          this.content = response.data.content;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getActions()",
          });
        });
    },
  },
  mounted() {
    this.getActions();
    this.getStatisticsData();
    this.getLastSuccesses();
  },
};
</script>

<style>
.sync.Success {
  border-left: 4px solid #689f38;
}
</style>
