<template>
  <div class="products ma-2">
    <v-row class="ma-2">
    <v-text-field
      v-model="searchQuery"
      :value=this.searchQuery
      dense
      outlined
      label="Wyszukiwana fraza"
      hide-details="auto"
      @keyup.enter="getProducts(page)"
      @keyup="setUrlParams()"
    ></v-text-field>
    </v-row>
    <v-row class="ma-2">
        <v-select 
          class="shrink pa-0 ma-0"
          hide-details="true"
          v-model="selectedDistributors"
          :items="distributorsList"
          dense
          outlined
          attach
          @change="setUrlParams()"
          label="Dostawcy"
          multiple
        >
        <template v-slot:item="{ item }">
          <img :src="require(`@/assets/icons/${item.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px">
        {{ item }}
        </template></v-select>
        <v-spacer></v-spacer>
        <v-icon @click="viewType='list'">mdi-view-agenda-outline</v-icon>
        <v-icon @click="viewType='grid'">mdi-view-grid-outline</v-icon>
    </v-row>
    <v-row class="ma-2" style="">
        <v-select 
          class="shrink pa-0 ma-0"
          dense
          outlined
          hide-details="true"
          v-model="sortingType"
          @change="setUrlParams()"
          :items="sortingTypes"
          item-text="text"
          item-value="value"
          attach
          label="Sortowanie"
        >
        </v-select>
        <v-checkbox class="mx-1" 
            v-model="availableOnly"
            :input-value="availableOnly"
            false-value=false
            true-value=true
            @change="setUrlParams()"
            dense
            :label="`Pokaż tylko dostępne`"
          ></v-checkbox>
          <v-spacer></v-spacer>
          <v-btn @click="getProducts(page)">Szukaj</v-btn>
    </v-row>
    
    <v-divider class="mt-n2"></v-divider>
    <v-row class="my-5" v-if='loading == true' align="center" justify="center">
      <v-progress-linear
            height="10"
            color="#9E9E9E"
            indeterminate
        ></v-progress-linear>
    </v-row>

    <!-- LISTA PRODUKTÓW -->
    <div v-if='loading == false'>
      <v-row class="mt-2 ml-2" v-if="this.isWarningMessage==true" style="color:red">
          {{ this.warningMessage }}
        </v-row>
      <div v-if="viewType=='grid'">
        <v-row class="mt-2">
          <v-col md="2" v-for="product in content" :key="product.id">
            <v-card elevation="2" class="pa-3" height="100%">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
              <div style="height:86px; overflow: hidden; font-family: Segoe UI; font-size: 14px;"  v-bind="attrs" v-on="on">
                <img :src="require(`@/assets/icons/${product.provider.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px">
                <a @click="handleClick(product)" target="_blank">
                  <v-icon small height="16px" style="vertical-align: top">mdi-magnify</v-icon>
                </a>
                
                  <b>{{product.name}}</b>
                
              </div>
              </template>
                <span>{{product.name}}</span>
              </v-tooltip>
              <v-divider></v-divider>
              <div class="mt-1">
                <div class="caption"><b>DOSTAWCA:</b> {{product.provider}}</div>
                <div class="caption"><b>CENA:</b> {{product.price.toFixed(2)}} {{product.currency}}</div>
                <div class="caption"><b>MP/N:</b> {{product.manufacturerPartNumber}}</div>
                <div class="caption" v-if="product.additionalPartNumbers!=null"><b>AMP/N:</b> {{product.additionalPartNumbers}}</div>
                <div class="caption"><b>V/ID:</b> {{product.vendorId}}</div>
                <div class="caption"><b>AKT.:</b> {{product.createdAt}}</div>
                <div>
                  <span v-if="product.availableInStock!=null" class="caption mx-1" :style="{color: getColor(product.availableInStock)}">dostępne {{product.availableInStock}} szt.</span>
                  <span v-if="product.availableInDeliv!=null" class="caption mx-1" :style="{color: getDeliveryColor(product.availableInDeliv)}">(w dostawie {{product.availableInDeliv}} szt.)</span>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <v-row align="center" v-if='totalPages > 1' justify="center">
          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7"></v-pagination>
        </v-row>
      </div>
      <div v-if="viewType=='list'">
        <v-container class="ml-n2" v-for="product in content" :key="product.id">
          <v-row class="ma-2">
              <h4>
                <img :src="require(`@/assets/icons/${product.provider.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px">
                {{product.name}}
              </h4>
          </v-row>
          <v-row class="my-n5">
            <v-col>
              <div>
                <a @click="handleClick(product)" target="_blank">
                  <v-icon small>mdi-magnify</v-icon>
                </a>
                <a :href="getLink(product.provider)+product.vendorId" target="_blank">
                        <v-icon small>mdi-open-in-new</v-icon>
                    </a>
                <span class="caption mx-1"><b>DOSTAWCA:</b> {{product.provider}} </span>
                <span class="caption mx-1"><b>CENA:</b> {{product.price.toFixed(2)}} {{product.currency}} </span>
                <span class="caption mx-1"><b>MP/N:</b> {{product.manufacturerPartNumber}}</span>
                <span class="caption mx-1" v-if="product.additionalPartNumbers!=null"><b>AMP/N:</b> {{product.additionalPartNumbers}}</span>
                <span class="caption mx-1"><b>V/ID:</b> {{product.vendorId}}</span>
                <span class="caption mx-1"><b>AKT.:</b> {{product.createdAt.substring(0,10)}}</span>
              </div>
            </v-col>
          </v-row>
          <v-row class="my-n5">
            <v-col>
              <div>
                <span v-if="product.availableInStock!=null" class="caption mx-1"><v-chip :color=getColor(product.availableInStock) text-color="#f5f5f5" x-small>DOSTĘPNE: {{product.availableInStock}}</v-chip></span>
                <span v-if="product.availableInDeliv!=null" class="caption mx-1"><v-chip x-small>W DOSTAWIE: {{product.availableInDeliv}}</v-chip></span>
                <span v-if="product.reservedInStock!=null" class="caption mx-1"><v-chip x-small>ZAREZERWOWANE: {{product.reservedInStock}}</v-chip></span>
                <span v-if="product.reservedInDeliv!=null" class="caption mx-1"><v-chip x-small>ZAREZEROWANE W DOSTAWIE: {{product.reservedInDeliv}}</v-chip></span>
              </div>
            </v-col>
          </v-row>
          <v-divider class="mt-6"></v-divider>
        </v-container>
        <v-row align="center" v-if='totalPages > 1' justify="center">
          <v-pagination v-model="currentPage" :length="totalPages" :total-visible="7"></v-pagination>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductsTable',
  data() {
    return {
      isWarningMessage: false,
      warningMessage: null,
      searchQuery: "",
      viewType: 'list',
      sortingType: 'availability',
      availableOnly: false,
      itemsPerPage: 30,
      page: 1,
      loading: false,
      totalPages: 1,
      content: [],
      distributorsList: [],
      selectedDistributors: [],
      sortingTypes: [
                     { text: "Dostępność", value: "availability"},
                     { text: "Cena rosnąco", value: "priceAsc"},
                     { text: "Cena malejąco", value: "priceDesc"},
                     { text: "Kod producenta A-Z", value: "producerAlphabetic"},
                     { text: "Nazwa produktu A-Z", value: "productNameAlphabetic"}
                    ], 
      headers: [
        { text: "ID", value: "vendorId", sortable: false},
        { text: "provider", value: "provider", sortable: false},
        { text: "Name", value: "name", sortable: false},
        { text: "Price", value: "price", sortable: false},
        { text: "AIS", value: "availableInStock", sortable: false, align: "center"},
        { text: "AID", value: "availableInDeliv", sortable: false, align: "center"},
        { text: "RIS", value: "reservedInStock", sortable: false, align: "center"},
        { text: "RID", value: "reservedInDeliv", sortable: false, align: "center"},         
      ],
      searchlinks: [{
                provider: 'INGRAM',
                link: 'https://www.ingrammicro24.com/pl/catalog/?search=',
            },
            {
                provider: 'WFIRMA',
                link: 'https://wfirma.pl/goods/view/',
            },
            {
                provider: 'TECHDATA',
                link: 'https://intouch.techdata.com/InTouch/MVC/Product/ProductDetails?ProductId=',
            },
            {
                provider: 'ALSO',
                link: 'https://www.also.com/ec/cms5/5410/ProductDetailData.do?prodId=',
            },
            {
                provider: 'ACTION',
                link: 'https://is3.action.pl/product/id-',
            },
            {
                provider: 'AB',
                link: 'https://www.abonline.pl/offer/pl/0/#/product/?pr=',
            }]
    }
  },
  methods: {
    //GET LINK TO DISTRIBUTOR PRODUCT
    getLink(distributor){
            for(var i=0;i<this.searchlinks.length;i++){
                var link = this.searchlinks[i];
                if(link['provider'] == distributor){
                    return link['link'];
                }
            }
        },
    //COPY VALUES FROM URL TO VUE CONTEXT
    setVarsBasedOnUrl(){
      if(this.$route.query.sortingType) this.searchQuery = this.$route.query.sortingType;
      if(this.$route.query.searchQuery) this.searchQuery = this.$route.query.searchQuery;
      if(this.$route.query.providers) this.selectedDistributors = this.$route.query.providers.split(',');
      if(this.$route.page) this.page.set(this.$route.page);
    },
    //UPDATE URL BASED ON VUE CONTEXT
    setUrlParams(){
      this.$router.push({query: {sortingType: this.sortingType, availableOnly: this.availableOnly, providers: this.selectedDistributors.join(','), searchQuery: this.searchQuery, page: this.page, offset: this.itemsPerPage}, })
    },
    //DOWNLOAD LIST OF PROVIDERS
    getDistributors(){
      this.$store.dispatch("getDistributors")
        .then((response) => {
          this.distributorsList = response.data;
          if(!this.$route.query.providers) this.selectedDistributors = response.data;
          this.setUrlParams();
        })
        .catch((error) => {
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Something went wrong with getDistributors() " + error});
      });
    },
    //DOWNLOAD PRODUCTS
    getProducts(page) {
      this.isWarningMessage = false;
      this.loading = true;
      let calculatedOffset = this.itemsPerPage * (page - 1);
      if(this.searchQuery.length<2 || this.searchQuery == ""){
        this.loading = false;
        this.warningMessage = "Wyszukiwana fraza musi mieć conajmniej dwa znaki."
        this.isWarningMessage = true;
        this.content = [];
        return;
      }
        this.$store.dispatch("getProductsList", {
          sortingType: this.sortingType,
          searchQuery: this.searchQuery,
          providers: this.selectedDistributors,
          availableOnly: this.availableOnly,
          limit: this.itemsPerPage,
          offset: calculatedOffset,
        })
        .then((response) => {
          this.content = response.data.content;
          this.loading = false;
          this.totalItems = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          if(response.data.content.length==0){
            this.warningMessage = "Nie znaleziono produktów."
            this.isWarningMessage = true;
            this.content = [];
          }
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Something went wrong with getProducts()",
        });
      });
    },
    handleClick(item) {
      this.$router.push("/product/"+item.id);
    },
    getColor(amount) {
      if(amount>0){
        return "#60a63d";
      } else if(amount<=0){
        return "#d14a28";
      }
    },
    getDeliveryColor(amount) {
      if(amount>0){
        return "#e07a14";
      } else if(amount<=0){
        return "#ffffff";
      }
    },
  },

  computed: {
    currentPage: {
      totalPages(){
        return this.content.totalPages;
      },
      get() {
        return parseInt(this.$route.query.page || 1)
      },
      set(newPage){
        this.$router.push({query: { ...this.$route.query, page: newPage}})
        .catch(() => {});
        this.getProducts(newPage);
      },
    }
  },

  created() {
    this.setVarsBasedOnUrl()
    this.getDistributors()
  },
}
</script>
