<template>
    <div>

    </div>
</template>

<script>
export default {
    created() {
        this.$store.dispatch('destroyToken')
        // eslint-disable-next-line no-unused-vars
        .then(response => { 
            this.$router.push({name: 'login'})
        })
    }
}
</script>