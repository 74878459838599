 <template>
  <div class="orderLineTable">
    <v-switch
      @click="getOrderPositions()"
      dense
      align="center"
      v-model="showAllocatedOnly"
      :label="'Show allocated only'"
    ></v-switch>

    <v-data-table
      dense
      height="670"
      fixed-header
      :headers="headers"
      :items="content"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :header-props="{ sortIcon: null }"
    >
      <template v-slot:top>
        <v-text-field
          @keydown.enter="getOrderPositions"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
          class="mx-4"
        ></v-text-field>
      </template>

      <template v-slot:item.provider="{ item }">
        <img :src="require(`@/assets/icons/${item.provider.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px; text-align: center; vertical-align: middle;">
      </template>

      <template v-slot:item.creationTime="{ item }">
        <span class="text-center">{{ getDate(item.creationTime) }}</span>
      </template>

      <template v-slot:item.allocatedQtyFromStock="{ item }">
        <v-chip :color="getColor(item.allocatedQtyFromStock)" outlined small>
          {{ item.allocatedQtyFromStock }}
        </v-chip>
      </template>

      <template v-slot:item.allocatedQtyInDelivery="{ item }">
        <v-chip v-if="item.allocatedQtyInDelivery!=null" :color="getColor1(item.allocatedQtyInDelivery)" outlined small>
          {{ item.allocatedQtyInDelivery }}
        </v-chip>
        <v-chip v-if="item.allocatedQtyInDelivery==null" disabled outlined small>
          -
        </v-chip>
      </template>

      <template v-slot:item.shipped="{ item }">
        <v-chip :color="getColor1(item.shipped)" outlined small>
          {{ item.shipped }}
        </v-chip>
      </template>

      <template v-slot:item.invoiced="{ item }">
        <v-chip v-if="item.invoiced!=null" :color="getColor2(item.invoiced)" outlined small>
          {{ item.invoiced }}
        </v-chip>
        <v-chip v-if="item.invoiced==null" disabled outlined small>
          -
        </v-chip>
      </template>

      <template v-slot:item.price="{ item }">
        {{ item.price }} {{ item.orderCurrency }}
      </template> 

      <template v-slot:item.isEnabled="{ item }">
        <v-switch
          v-model="item.isEnabled"
          dense
          @change="changeState(item.id, item.isEnabled)"
        ></v-switch>
      </template>
    </v-data-table>
  </div>
</template>

<style scoped>
  .v-data-table>.v-data-table__wrapper>table>tbody>tr>td {
   font-size: 7px;
 }
</style>

<script>
export default {
  methods: {
    getDate(dateTime){
      let temp = dateTime.split(" ");
      return temp[0];
    },
    getColor(AllocInStock) {
      if (AllocInStock < 1) return "gray";
      else return "green";
    },
    getColor1(allocatedQtyInDelivery) {
      if (allocatedQtyInDelivery > 1) return "orange";
      else return "gray";
    },
    getColor2(allocatedQtyInDelivery) {
      if (allocatedQtyInDelivery > 1) return "blue";
      else return "gray";
    },
    changeState(id, state) {
      this.$store
        .dispatch("changeOrderLineState", {
          id: id,
          state: state,
        })
        .then(() => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Changed",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with changeState()",
          });
        });
    },

    getOrderPositions() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = itemsPerPage * (page - 1);

      if (this.search != "") {
        this.$store
          .dispatch("getOrderPositionsWithSearchQuery", {
            itemsPerPage: itemsPerPage,
            pageNumber: pageNumber,
            searchquery: this.search,
            allocOnly: this.showAllocatedOnly,
          })
          .then((response) => {
            this.content = response.data.content;
            this.loading = false;
            this.totalItems = response.data.totalElements;
            this.totalPages = response.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Something went wrong with getOrderPositions()",
            });
          });
      } else if (this.sortBy!=null && this.sortBy!="" && this.sortDesc!=null) {
        if(this.sortDesc){
          this.direction = "ASC";
        } else {
          this.direction = "DESC";
        }
        this.$store
          .dispatch("getOrderPositionsWithSorting", {
            itemsPerPage: itemsPerPage,
            pageNumber: pageNumber,
            sortBy: this.sortBy,
            dir: this.direction,
            allocOnly: this.showAllocatedOnly,
          })
          .then((response) => {
            this.content = response.data.content;
            this.loading = false;
            this.totalItems = response.data.totalElements;
            this.totalPages = response.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Something went wrong with getOrderPositions()",
            });
          });
      } else {
        this.$store
          .dispatch("getOrderPositions", {
            itemsPerPage: itemsPerPage,
            pageNumber: pageNumber,
            allocOnly: this.showAllocatedOnly,
          })
          .then((response) => {
            this.content = response.data.content;
            this.loading = false;
            this.totalItems = response.data.totalElements;
            this.totalPages = response.data.totalPages;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Something went wrong with getOrderPositions()",
            });
          });
      }
    },
  },

  watch: {
    options: {
      handler() {
        this.getOrderPositions();
      },
    },
    deep: true,
  },

  mounted() {
    this.getOrderPositions();
  },

  name: "Positions",
  data() {
    return {
      search: "",
      sortBy: null,
      sortDesc: null,
      direction: null,
      totalItems: 0,
      showAllocatedOnly: false,
      loading: true,
      options: { page: 1 },
      content: [],
      footerProps: { "items-per-page-options": [20, 50, 100, 200, 300] },
      headers: [
        {
          id: "1",
          text: "DIS",
          align: "center",
          sortable: false,
          value: "provider",
          width: "20px"
        },
        {
          id: "2",
          text: "Created at",
          align: "start",
          value: "creationTime",
          width: "120px",
        },
        {
          id: "3",
          text: "ID",
          align: "start",
          sortable: false,
          value: "externalId",
        },
        { id: "4", text: "VPN", value: "vpn", sortable: false },
        { id: "5", text: "Product Name", value: "productName", sortable: false },
        { id: "6", text: "Qty", value: "orderQty", width: 20, align: "center" },
        { id: "7", text: "Price", value: "price", width: 150, align: "right" },
        {
          id: "8",
          text: "AIC",
          value: "allocatedQtyFromStock",
          align: "center",
        },
        {
          id: "9",
          text: "AID",
          value: "allocatedQtyInDelivery",
          align: "center",
        },
        { id: "10", text: "SHP", value: "shipped", align: "center" },
        { id: "11", text: "INV", value: "invoiced", align: "center" },
        //{ text: "VendId", value: "itemId" },
        { id: "12", text: "Enabled", value: "isEnabled", sortable: false },
      ],
    };
  },
};
</script>
