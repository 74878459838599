<template>
  <div class="my-3">
    <v-row class="mt-2" align="top">
      <v-col cols="2">
        <v-select
          dense
          multiple
          label="Typ zarządzania"
          v-model="selectedStates"
          :items="states"
          @change="
            setUrlParams();
            getOffers(1);
          "
        >
          <template v-slot:item="{ item }">
            <div v-if="item == 'MANAGED'">
              <v-icon small>mdi-cog-outline</v-icon> Managed (zarządzany)
            </div>
            <div v-if="item == 'MANUAL'">
              <v-icon small>mdi-wrench-outline</v-icon> Manual (manualny)
            </div>
            <div v-if="item == 'OMITTED'">
              <v-icon small>mdi-cancel</v-icon> Omitted (pominięty)
            </div>
          </template>
        </v-select>
        <v-select
          dense
          v-model="selectedSorting"
          label="Typ sortowania"
          :items="sorting"
          item-text="text"
          item-value="value"
          @change="
            setUrlParams();
            getOffers(1);
          "
        >
        </v-select>
      </v-col>
      <v-col cols="10">
        <v-text-field
          dense
          append-icon="mdi-magnify"
          hide-details="auto"
          v-model="searchQuery"
          @keyup.enter="
            setUrlParams();
            getOffers(1);
          "
        ></v-text-field>
        <div class="mt-5">
          <v-row>
            <v-checkbox
              class="ml-4"
              v-model="groupOnly"
              dense
              @click="
                setUrlParams();
                getOffers(1);
              "
              label="Pokaż tylko z grupami"
            ></v-checkbox>
            <v-checkbox
              class="ml-4"
              v-model="groupPreview"
              dense
              @click="
                setUrlParams();
                getOffers(1);
              "
              label="Podgląd grup"
            ></v-checkbox>
            <v-btn class="ml-5" @click="forceAdd()" elevation="0"
              ><v-icon>mdi-table-large-plus</v-icon> POBIERZ NOWE</v-btn
            >
            <v-btn @click="forceUpdate()" elevation="0"
              ><v-icon>mdi-table-refresh</v-icon> AKTUALIZUJ</v-btn
            >
            <v-btn @click="forceCalculation()" elevation="0"
              ><v-icon>mdi-calculator-variant-outline</v-icon> PRZELICZ</v-btn
            >
          </v-row>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-simple-table dense style="width: 100%">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">ID</th>
              <th class="text-left">Wfirma ID</th>
              <th class="text-left">Nazwa</th>
              <th class="text-left">Kod producenta</th>
              <th class="text-left">Stan</th>
              <th class="text-left">Cena netto</th>
              <th class="text-left" style="min-width: 160px">Cena brutto</th>
              <th class="text-center" style="min-width: 90px">Narzut (%)</th>
              <th class="text-left">Zarządzanie</th>
              <th class="text-left" style="width: 80px"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in data" :key="item.id">
              <td>{{ item.id }}</td>
              <td>{{ item.warehouseCode }}</td>
              <td>
                <a
                  :href="
                    'https://sklep193475.shoparena.pl/admin/products/edit/id/' +
                    item.id
                  "
                  target="_blank"
                  ><v-icon small>mdi-open-in-new</v-icon></a
                >
                <span
                  v-bind:style="[
                    groupPreview == true && item.groupList.length > 0
                      ? { 'font-weight': 'bold' }
                      : {},
                  ]"
                  >{{ item.name }}</span
                ><br />
                <ol
                  v-if="groupPreview == true && item.groupList.length > 0"
                  style="font-size: 13px"
                >
                  <li
                    v-for="group in item.groupList"
                    :key="group.id"
                    class="ml-7"
                  >
                    {{ group.name }}
                    <ul>
                      <li
                        v-for="groupItem in group.itemList"
                        :key="groupItem.id"
                      >
                        <a
                          :href="`https://wfirma.pl/goods/view/${groupItem.warehouseId}`"
                          target="_blank"
                          style="color: inherit; text-decoration: inherit"
                          >{{ groupItem.name }}</a
                        >
                        ({{ groupItem.mpn }})
                      </li>
                    </ul>
                  </li>
                </ol>
              </td>
              <td>{{ item.manufacturerPartNumber }}</td>
              <td>
                <v-text-field
                  v-if="item.state == 'MANUAL'"
                  style="width: 50px; height: 100%; font-size: 0.875rem"
                  dense
                  suffix="szt."
                  @change="item.notifyAboutChange = true"
                  v-model="item.manualStock"
                >
                </v-text-field>
                <v-text-field
                  v-if="item.state == 'MANAGED'"
                  style="
                    width: 50px;
                    height: 100%;
                    font-size: 0.875rem;
                    pointer-events: none;
                    opacity: 0.3;
                  "
                  dense
                  readonly
                  suffix="szt."
                  :value="item.automaticStock"
                >
                </v-text-field>
                <v-text-field
                  v-if="item.state == 'OMITTED'"
                  style="
                    width: 50px;
                    height: 100%;
                    font-size: 0.875rem;
                    pointer-events: none;
                    opacity: 0.3;
                  "
                  dense
                  readonly
                  suffix="szt."
                  :value="item.omittedStock"
                >
                </v-text-field>
              </td>

              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">
                    <div
                      v-if="item.state == 'MANUAL'"
                      style="opacity: 0.3; white-space: nowrap"
                    >
                      {{ (item.manualPrice / 1.23).toFixed(2) }} PLN
                    </div>
                    <div
                      v-if="item.state == 'MANAGED'"
                      style="opacity: 0.3; white-space: nowrap"
                    >
                      {{ (item.automaticPrice / 1.23).toFixed(2) }} PLN
                    </div>
                    <div
                      v-if="item.state == 'OMITTED'"
                      style="opacity: 0.3; text-align: center"
                    >
                      -
                    </div>
                  </td></template
                ><span
                  >Cena w sklepie:
                  {{ (item.currentPrice / 1.23).toFixed(2) }} PLN netto</span
                >
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <td v-bind="attrs" v-on="on">
                    <v-text-field
                      v-if="item.state == 'MANUAL'"
                      style="width: 100px; height: 100%; font-size: 0.875rem"
                      dense
                      suffix="PLN"
                      @change="item.notifyAboutChange = true"
                      v-model="item.manualPrice"
                    >
                    </v-text-field>
                    <v-text-field
                      v-if="item.state == 'MANAGED'"
                      style="
                        width: 100px;
                        height: 100%;
                        font-size: 0.875rem;
                        pointer-events: none;
                        opacity: 0.3;
                        display: inline-block;
                        vertical-align: middle;
                      "
                      dense
                      readonly
                      suffix="PLN"
                      :value="item.automaticPrice"
                    >
                    </v-text-field>
                    <v-icon
                      v-if="
                        item.state == 'MANAGED' &&
                        item.currentPrice != item.automaticPrice
                      "
                      color="red darken-2"
                      style="vertical-align: middle"
                      >mdi-exclamation</v-icon
                    >
                    <v-text-field
                      v-if="item.state == 'OMITTED'"
                      style="
                        width: 100px;
                        height: 100%;
                        font-size: 0.875rem;
                        pointer-events: none;
                        opacity: 0.3;
                      "
                      dense
                      readonly
                      value="BRAK"
                    >
                    </v-text-field></td></template
                ><span>Cena w sklepie: {{ item.currentPrice }} PLN brutto</span>
              </v-tooltip>
              <td class="text-center">
                <div v-if="item.state != 'MANAGED'">-</div>
                <v-text-field
                  v-if="item.state == 'MANAGED'"
                  v-on:dblclick="
                    item.customMarginPercentage = !item.customMarginPercentage
                  "
                  :style="[
                    item.customMarginPercentage == false
                      ? { opacity: '0.15' }
                      : { opacity: '1' },
                  ]"
                  style="width: 50px; height: 100%; font-size: 0.875rem"
                  :value="item.customMarginPercentageValue"
                  dense
                  :min="0"
                  :max="1000"
                  type="number"
                  @change="
                    (selected) => {
                      item.customMarginPercentageValue = selected;
                      item.notifyAboutChange = true;
                    }
                  "
                />
              </td>
              <td>
                <v-select
                  style="width: 140px; height: 100%; font-size: 0.875rem"
                  dense
                  :items="states"
                  :value="item.state"
                  return-object
                  @change="
                    (selected) => {
                      changeState(selected, item.id);
                      item.notifyAboutChange = true;
                    }
                  "
                >
                  <template slot="prepend-inner">
                    <div v-if="item.state == 'MANAGED'">
                      <v-icon small>mdi-cog-outline</v-icon>
                    </div>
                    <div v-if="item.state == 'MANUAL'">
                      <v-icon small>mdi-wrench-outline</v-icon>
                    </div>
                    <div v-if="item.state == 'OMITTED'">
                      <v-icon small>mdi-cancel</v-icon>
                    </div>
                  </template>
                </v-select>
              </td>
              <td>
                <v-icon
                  v-if="item.groupList.length > 0"
                  color="green"
                  @click="groupEdit(item)"
                  >mdi-link-box-variant-outline</v-icon
                >
                <v-icon
                  v-if="item.groupList.length == 0"
                  @click="groupEdit(item)"
                  >mdi-link-box-variant-outline</v-icon
                >
                <v-icon
                  v-if="item.notifyAboutChange == false"
                  @click="
                    saveOffer(
                      item.id,
                      item.state,
                      item.manualPrice,
                      item.manualStock,
                      item.customMarginPercentage,
                      item.customMarginPercentageValue
                    )
                  "
                  >mdi-content-save-move-outline</v-icon
                >
                <v-icon
                  v-if="item.notifyAboutChange == true"
                  color="red darken-2"
                  @click="
                    saveOffer(
                      item.id,
                      item.state,
                      item.manualPrice,
                      item.manualStock,
                      item.customMarginPercentage,
                      item.customMarginPercentageValue
                    );
                    item.notifyAboutChange = false;
                  "
                  >mdi-content-save-move-outline</v-icon
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-row>
    <v-row align="center" v-if="totalPages > 1" justify="center">
      <v-pagination
        v-model="currentPage"
        :length="totalPages"
        :total-visible="7"
      ></v-pagination>
    </v-row>
    <offer-group-modal v-model="showOfferGroupModal" :data="offerGroupData" />
  </div>
</template>

<script>
import OfferGroupModal from "../components/OfferGroupModal.vue";

export default {
  components: { OfferGroupModal },
  methods: {
    groupEdit(data) {
      this.offerGroupData = data;
      this.showOfferGroupModal = true;
      console.log(this.offerGroupData);
    },
    setBaseParams() {
      if (this.$route.query.selectedSorting)
        this.selectedSorting = this.$route.query.selectedSorting;
      if (this.$route.query.searchQuery)
        this.searchQuery = this.$route.query.searchQuery;
      if (this.$route.query.selectedStates)
        this.selectedStates = this.$route.query.selectedStates.split(",");
      if (this.$route.page) this.page.set(this.$route.page);
      if (this.$route.query.groupOnly)
        this.groupOnly = this.$route.query.groupOnly === "true";
      if (this.$route.query.groupPreview)
        this.groupPreview = this.$route.query.groupPreview === "true";
    },

    setUrlParams() {
      this.$router.push({
        query: {
          selectedSorting: this.selectedSorting,
          selectedStates: this.selectedStates.join(","),
          searchQuery: this.searchQuery,
          page: this.page,
          groupOnly: this.groupOnly,
          groupPreview: this.groupPreview,
        },
      });
    },

    forceCalculation() {
      this.$store
        .dispatch("forceRwcOfferCalculation")
        .then((response) => {
          console.log(response);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Requested update",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getOffers()",
          });
        });
    },

    saveOffer(
      id,
      state,
      manualPrice,
      manualStock,
      isCustomMargin,
      customMarginValue
    ) {
      this.$store
        .dispatch("saveRwcOffer", {
          id: id,
          state: state,
          manualStock: manualStock,
          manualPrice: manualPrice,
          isCustomMargin: isCustomMargin,
          customMarginValue: customMarginValue,
        })
        .then((response) => {
          console.log(response);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Zaktualizowano ofertę",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with saveOffer()",
          });
        });
    },

    changeState(selected, id) {
      this.data.forEach((x) => {
        if (x.id == id) {
          x.state = selected;
        }
      });
    },

    getOffers(page) {
      let calculatedOffset = this.itemsPerPage * (page - 1);
      this.$store
        .dispatch("getRwcOffers", {
          states: this.selectedStates,
          limit: this.itemsPerPage,
          offset: calculatedOffset,
          sortingType: this.selectedSorting,
          searchQuery: this.searchQuery,
          groupOnly: this.groupOnly,
        })
        .then((response) => {
          this.data = response.data.content;
          this.data.forEach((_, index) => {
            this.$set(this.data[index], "notifyAboutChange", false);
          });
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getOffers()",
          });
        });
    },

    forceUpdate() {
      this.$store
        .dispatch("forceRwcOfferUpdate")
        .then((response) => {
          console.log(response);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Requested update",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with forceUpdate()",
          });
        });
    },

    forceAdd() {
      this.$store
        .dispatch("forceRwcOfferAdd")
        .then((response) => {
          console.log(response);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Requested update",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with forceAdd()",
          });
        });
    },
  },
  data() {
    return {
      itemsPerPage: 30,
      page: 1,
      totalPages: null,
      loading: false,
      data: null,
      offerGroupData: null,
      showOfferGroupModal: false,
      groupOnly: false,
      groupPreview: false,
      selectedStates: ["MANAGED", "MANUAL", "OMITTED"],
      states: ["MANAGED", "MANUAL", "OMITTED"],
      selectedSorting: "NEWEST",
      sorting: [
        { value: "NEWEST", text: "Od najnowszych" },
        { value: "STOCKASC", text: "Stan magazynowy rosnąco" },
        { value: "STOCKDSC", text: "Stan magazynowy malejąco" },
      ],
      searchQuery: "",
    };
  },

  computed: {
    currentPage: {
      totalPages() {
        return this.content.totalPages;
      },
      get() {
        return parseInt(this.$route.query.page || 1);
      },
      set(newPage) {
        this.$router
          .push({ query: { ...this.$route.query, page: newPage } })
          .catch(() => {});
        this.getOffers(newPage);
      },
    },
  },
  created() {
    this.setBaseParams();
  },
  mounted() {
    this.getOffers(1);
  },
};
</script>
