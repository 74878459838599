var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-2"},[_c('order-modal',{attrs:{"item":this.orderData},model:{value:(_vm.showOrderModal),callback:function ($$v) {_vm.showOrderModal=$$v},expression:"showOrderModal"}}),_c('shipping-status-modal',{attrs:{"data":this.shipmentStatusData,"trackingNumber":this.statusTrackingNumber},model:{value:(_vm.showShippingStatusModal),callback:function ($$v) {_vm.showShippingStatusModal=$$v},expression:"showShippingStatusModal"}}),_c('h1',{staticClass:"overline"},[_vm._v("Przesyłki")]),_c('v-divider'),_c('v-row',[_c('v-col',[(_vm.data.content.length==0)?_c('div',[_c('h1',[_vm._v("BRAK DANYCH")])]):_vm._e(),(_vm.data.content.length>0)?_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v(" ID ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Firma kurierska ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Numer monitorowania ")]),_c('th',{staticClass:"text-left"},[_vm._v(" Data utworzenia ")]),_c('th',{staticClass:"text-right"},[_vm._v(" Akcje ")])])]),_c('tbody',_vm._l((_vm.data.content),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.shippingProvider))]),_c('td',[_vm._v(_vm._s(item.trackingNumber))]),_c('td',[_vm._v(_vm._s(item.createdAt))]),_c('td',{staticClass:"text-right"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.getShipmentStatus(item.trackingNumber, item.shippingProvider)}}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Pokaż status przesyłki")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.getLabel(item.id, item.shippingProvider)}}},[_vm._v("mdi-file-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Pobierz etykietę przesyłki")])]),(item.orderId!=null)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.showOrder(item.orderId)}}},[_vm._v("mdi-text-box-search-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Pokaż powiązane zamówienie")])]):_vm._e(),(item.orderId==null)?_c('v-icon',{attrs:{"disabled":""}},[_vm._v("mdi-text-box-search-outline")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-icon',{on:{"click":function($event){return _vm.removeShipment(item.trackingNumber, item.shippingProvider)}}},[_vm._v("mdi-delete-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Usuń przesyłkę")])])],1)])}),0)]},proxy:true}],null,false,2181451071)}):_vm._e()],1)],1),(_vm.totalPages > 1)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":3},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }