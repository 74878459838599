<template>
  <v-dialog v-model="show" width="1400px">
    <v-card class="pa-4">
        <h1 class="overline">Status przesyłki nr. {{trackingNumber}}</h1>
        <v-simple-table v-if="data!=null" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      Status
                    </th>
                    <th class="text-left">
                      Opis
                    </th>
                    <th class="text-left">
                      Data oraz czas
                    </th>
                    <th class="text-left">
                      Terminal
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data" :key="item.status">
                    <td>{{ item.status }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.timestamp }}</td>
                    <td>{{ item.terminal }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    data: null,
    trackingNumber: null,
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
};
</script>