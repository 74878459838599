<template>
  <v-dialog v-model="show" width="1400px">
    <v-card class="pa-4">
        <v-col cols="12" md="12">
            <div class="overline">
            Podgląd rezerwacji nr. {{data.externalId}}
            <v-divider></v-divider>
            </div>
            
            <v-row class="mt-2">
                <v-col md="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                        <tbody>
                            <tr>
                                <td class="text-right" style="width:250px">Dostawca:</td>
                                <td class="text-left">
                                    <img :src="require(`@/assets/icons/${data.provider.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px; text-align: center; vertical-align: middle;">
                                    {{data.provider}}
                                </td>
                            </tr>
                            <tr>
                                <td class="text-right">Zewnętrzny numer rezerwacji:</td>
                                <td class="text-left">{{data.externalId}}</td>
                            </tr>
                            <tr>
                                <td class="text-right">Data utworzenia:</td>
                                <td class="text-left">{{data.creationTime}}</td>
                            </tr>
                            <tr>
                                <td class="text-right">Status:</td>
                                <td class="text-left">{{data.status}}</td>
                            </tr>
                            <tr>
                                <td class="text-right">Wartość netto:</td>
                                <td class="text-left">{{data.orderTotalNetAmount}} {{data.orderCurrency}}</td>
                            </tr>
                            <tr v-if="data.referenceNumber!=''">
                                <td class="text-right">Numer referencyjny:</td>
                                <td class="text-left">{{data.referenceNumber}}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <div class="overline">
                        Produkty w rezerwacji
                    </div>
                    <v-divider></v-divider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="12">
                    <v-simple-table dense>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-center">
                                        VPN
                                    </th>
                                    <th class="text-left">
                                        Nazwa produkty
                                    </th>
                                    <th class="text-center">
                                        Szt.
                                    </th>
                                    <th>
                                        Cena
                                    </th>
                                    <th class="text-center">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            ZLM
                                            </span>
                                        </template>
                                        <span>Zaalokowana liczba szt. w magazynie</span>
                                        </v-tooltip>
                                    </th>
                                    <th class="text-center">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            ZLD
                                            </span>
                                        </template>
                                        <span>Zaalokowana liczba szt. w dostawie</span>
                                        </v-tooltip>
                                    </th>
                                    <th class="text-center">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            W
                                            </span>
                                        </template>
                                        <span>Wysłana liczba sztuk</span>
                                        </v-tooltip>
                                    </th>
                                    <th class="text-center">
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            F
                                            </span>
                                        </template>
                                        <span>Zafakturowana liczba sztuk</span>
                                        </v-tooltip>
                                    </th>
                                    <th>
                                        <v-tooltip bottom>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span
                                            v-bind="attrs"
                                            v-on="on"
                                            >
                                            Włączony
                                            </span>
                                        </template>
                                        <span>Włącz aby produkt był brany pod uwagę podczas synchronizacji</span>
                                        </v-tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in data.orderPositions" :key="item.lineNumber">
                                    <td class="text-center">{{ item.vpn }}</td>
                                    <td>{{ item.productName }}</td>
                                    <td class="text-center">{{ item.orderQty }}</td>
                                    <td>{{ item.price }} {{ item.orderCurrency }}</td>
                                    <td class="text-center" v-if="item.allocatedQtyInStock!=null">{{ item.allocatedQtyInStock }}</td>
                                    <td class="text-center" v-if="item.allocatedQtyInStock==null">-</td>
                                    <td class="text-center" v-if="item.allocatedQtyInDelivery!=null">{{ item.allocatedQtyInDelivery }}</td>
                                    <td class="text-center" v-if="item.allocatedQtyInDelivery==null">-</td>
                                    <td class="text-center">{{ item.shipped }}</td>
                                    <td class="text-center" v-if="item.allocatedQtyInDelivery!=null">{{ item.invoiced }}</td>
                                    <td class="text-center" v-if="item.invoiced==null">-</td>
                                    <td class="text-center">
                                        <v-switch
                                        v-model="item.isEnabled"
                                        dense
                                        @change="changeState(item.id, item.isEnabled)"
                                        ></v-switch>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-col>
            </v-row>
        </v-col>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  mounted() {
   
  },
  methods: {
    changeState(id, state) {
      this.$store
        .dispatch("changeOrderLineState", {
          id: id,
          state: state,
        })
        .then(() => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Changed",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with changeState()",
          });
        });
    }
  },
  data() {
    return {

    };
  },
  props: {
    value: Boolean,
    data: null,
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
};
</script>