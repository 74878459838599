var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"orderTable"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","height":"720","fixed-header":"","headers":_vm.headers,"items":_vm.content,"loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItems,"footer-props":_vm.footerProps,"header-props":{ sortIcon: null }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.creationTime",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.getDate(item.createdAt)))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.price.toFixed(2))+" "+_vm._s(item.currency)+" ")]}},{key:"item.priceWithMargin",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.priceWithMargin.toFixed(2))+" "+_vm._s(item.currency)+" ")]}},{key:"item.publicatedPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.publicatedPrice.toFixed(2))+" "+_vm._s(item.currency)+" ")]}},{key:"item.vendorId",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://wfirma.pl/goods/view/" + (item.vendorId)),"target":"_blank"}},[_vm._v(_vm._s(item.vendorId))])]}},{key:"item.shoperProductId",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":("https://komputerydlafirm.pl/pl/p/o/" + (item.shoperProductId)),"target":"_blank"}},[_vm._v(_vm._s(item.shoperProductId))])]}},{key:"footer.page-text",fn:function(){return [(_vm.onDemandProgressPossible)?_c('v-icon',{on:{"click":function($event){return _vm.evaluateProductWarningsOnDemand()}}},[_vm._v("mdi-refresh")]):_vm._e(),(_vm.onDemandProgressLoading)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":"grey"}}):_vm._e()]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }