import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import store from './store'


Vue.config.productionTip = false
Vue.prototype.$http = axios

//axios.defaults.baseURL="https://localhost:8082/";

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(record => record.meta.requiresAuth)) {
//     store.dispatch('authorizeToken', {}) // eslint-disable-next-line no-unused-vars
//       .then(response => { 
//         next({ name: 'dashboard' })
//       }) // eslint-disable-next-line no-unused-vars
//       .catch(error => {
//         if(error.response.status == '403'){
//           router.go('/login')
//           store.dispatch('snackbar/setSnackbar',{color:'error', text: "Not authorized by backend"})
//         } else {
//           next()
//         }
//       })
//     // if (!store.getters.loggedIn) {
//     //   next({
//     //     name: 'login',
//     //   })
//     // } else {
//     //   next()
//     // }
//   } else if (to.matched.some(record => record.meta.requiresVisitor)) {

//     if (store.getters.loggedIn) {
      
//       next({
//         name: 'dashboard',
//       })
//     } else {
//       next()
//     }
//   } else {
//     next() 
//   }
// })


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
