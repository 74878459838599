var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"my-3"},[_c('v-row',{staticClass:"mt-2",attrs:{"align":"top"}},[_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"dense":"","multiple":"","label":"Typ zarządzania","items":_vm.states},on:{"change":function($event){_vm.setUrlParams();
          _vm.getOffers(1);}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
          var item = ref.item;
return [(item == 'MANAGED')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog-outline")]),_vm._v(" Managed (zarządzany) ")],1):_vm._e(),(item == 'MANUAL')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wrench-outline")]),_vm._v(" Manual (manualny) ")],1):_vm._e(),(item == 'OMITTED')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cancel")]),_vm._v(" Omitted (pominięty) ")],1):_vm._e()]}}]),model:{value:(_vm.selectedStates),callback:function ($$v) {_vm.selectedStates=$$v},expression:"selectedStates"}}),_c('v-select',{attrs:{"dense":"","label":"Typ sortowania","items":_vm.sorting,"item-text":"text","item-value":"value"},on:{"change":function($event){_vm.setUrlParams();
          _vm.getOffers(1);}},model:{value:(_vm.selectedSorting),callback:function ($$v) {_vm.selectedSorting=$$v},expression:"selectedSorting"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-text-field',{attrs:{"dense":"","append-icon":"mdi-magnify","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.setUrlParams();
          _vm.getOffers(1);}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}),_c('div',{staticClass:"mt-5"},[_c('v-row',[_c('v-checkbox',{staticClass:"ml-4",attrs:{"dense":"","label":"Pokaż tylko z grupami"},on:{"click":function($event){_vm.setUrlParams();
              _vm.getOffers(1);}},model:{value:(_vm.groupOnly),callback:function ($$v) {_vm.groupOnly=$$v},expression:"groupOnly"}}),_c('v-checkbox',{staticClass:"ml-4",attrs:{"dense":"","label":"Podgląd grup"},on:{"click":function($event){_vm.setUrlParams();
              _vm.getOffers(1);}},model:{value:(_vm.groupPreview),callback:function ($$v) {_vm.groupPreview=$$v},expression:"groupPreview"}}),_c('v-btn',{staticClass:"ml-5",attrs:{"elevation":"0"},on:{"click":function($event){return _vm.forceAdd()}}},[_c('v-icon',[_vm._v("mdi-table-large-plus")]),_vm._v(" POBIERZ NOWE")],1),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.forceUpdate()}}},[_c('v-icon',[_vm._v("mdi-table-refresh")]),_vm._v(" AKTUALIZUJ")],1),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.forceCalculation()}}},[_c('v-icon',[_vm._v("mdi-calculator-variant-outline")]),_vm._v(" PRZELICZ")],1)],1)],1)],1)],1),_c('v-row',[_c('v-simple-table',{staticStyle:{"width":"100%"},attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Wfirma ID")]),_c('th',{staticClass:"text-left"},[_vm._v("Nazwa")]),_c('th',{staticClass:"text-left"},[_vm._v("Kod producenta")]),_c('th',{staticClass:"text-left"},[_vm._v("Stan")]),_c('th',{staticClass:"text-left"},[_vm._v("Cena netto")]),_c('th',{staticClass:"text-left",staticStyle:{"min-width":"160px"}},[_vm._v("Cena brutto")]),_c('th',{staticClass:"text-center",staticStyle:{"min-width":"90px"}},[_vm._v("Narzut (%)")]),_c('th',{staticClass:"text-left"},[_vm._v("Zarządzanie")]),_c('th',{staticClass:"text-left",staticStyle:{"width":"80px"}})])]),_c('tbody',_vm._l((_vm.data),function(item){return _c('tr',{key:item.id},[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(_vm._s(item.warehouseCode))]),_c('td',[_c('a',{attrs:{"href":'https://sklep193475.shoparena.pl/admin/products/edit/id/' +
                  item.id,"target":"_blank"}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-open-in-new")])],1),_c('span',{style:([
                  _vm.groupPreview == true && item.groupList.length > 0
                    ? { 'font-weight': 'bold' }
                    : {} ])},[_vm._v(_vm._s(item.name))]),_c('br'),(_vm.groupPreview == true && item.groupList.length > 0)?_c('ol',{staticStyle:{"font-size":"13px"}},_vm._l((item.groupList),function(group){return _c('li',{key:group.id,staticClass:"ml-7"},[_vm._v(" "+_vm._s(group.name)+" "),_c('ul',_vm._l((group.itemList),function(groupItem){return _c('li',{key:groupItem.id},[_c('a',{staticStyle:{"color":"inherit","text-decoration":"inherit"},attrs:{"href":("https://wfirma.pl/goods/view/" + (groupItem.warehouseId)),"target":"_blank"}},[_vm._v(_vm._s(groupItem.name))]),_vm._v(" ("+_vm._s(groupItem.mpn)+") ")])}),0)])}),0):_vm._e()]),_c('td',[_vm._v(_vm._s(item.manufacturerPartNumber))]),_c('td',[(item.state == 'MANUAL')?_c('v-text-field',{staticStyle:{"width":"50px","height":"100%","font-size":"0.875rem"},attrs:{"dense":"","suffix":"szt."},on:{"change":function($event){item.notifyAboutChange = true}},model:{value:(item.manualStock),callback:function ($$v) {_vm.$set(item, "manualStock", $$v)},expression:"item.manualStock"}}):_vm._e(),(item.state == 'MANAGED')?_c('v-text-field',{staticStyle:{"width":"50px","height":"100%","font-size":"0.875rem","pointer-events":"none","opacity":"0.3"},attrs:{"dense":"","readonly":"","suffix":"szt.","value":item.automaticStock}}):_vm._e(),(item.state == 'OMITTED')?_c('v-text-field',{staticStyle:{"width":"50px","height":"100%","font-size":"0.875rem","pointer-events":"none","opacity":"0.3"},attrs:{"dense":"","readonly":"","suffix":"szt.","value":item.omittedStock}}):_vm._e()],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[(item.state == 'MANUAL')?_c('div',{staticStyle:{"opacity":"0.3","white-space":"nowrap"}},[_vm._v(" "+_vm._s((item.manualPrice / 1.23).toFixed(2))+" PLN ")]):_vm._e(),(item.state == 'MANAGED')?_c('div',{staticStyle:{"opacity":"0.3","white-space":"nowrap"}},[_vm._v(" "+_vm._s((item.automaticPrice / 1.23).toFixed(2))+" PLN ")]):_vm._e(),(item.state == 'OMITTED')?_c('div',{staticStyle:{"opacity":"0.3","text-align":"center"}},[_vm._v(" - ")]):_vm._e()])]}}],null,true)},[_c('span',[_vm._v("Cena w sklepie: "+_vm._s((item.currentPrice / 1.23).toFixed(2))+" PLN netto")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[(item.state == 'MANUAL')?_c('v-text-field',{staticStyle:{"width":"100px","height":"100%","font-size":"0.875rem"},attrs:{"dense":"","suffix":"PLN"},on:{"change":function($event){item.notifyAboutChange = true}},model:{value:(item.manualPrice),callback:function ($$v) {_vm.$set(item, "manualPrice", $$v)},expression:"item.manualPrice"}}):_vm._e(),(item.state == 'MANAGED')?_c('v-text-field',{staticStyle:{"width":"100px","height":"100%","font-size":"0.875rem","pointer-events":"none","opacity":"0.3","display":"inline-block","vertical-align":"middle"},attrs:{"dense":"","readonly":"","suffix":"PLN","value":item.automaticPrice}}):_vm._e(),(
                      item.state == 'MANAGED' &&
                      item.currentPrice != item.automaticPrice
                    )?_c('v-icon',{staticStyle:{"vertical-align":"middle"},attrs:{"color":"red darken-2"}},[_vm._v("mdi-exclamation")]):_vm._e(),(item.state == 'OMITTED')?_c('v-text-field',{staticStyle:{"width":"100px","height":"100%","font-size":"0.875rem","pointer-events":"none","opacity":"0.3"},attrs:{"dense":"","readonly":"","value":"BRAK"}}):_vm._e()],1)]}}],null,true)},[_c('span',[_vm._v("Cena w sklepie: "+_vm._s(item.currentPrice)+" PLN brutto")])]),_c('td',{staticClass:"text-center"},[(item.state != 'MANAGED')?_c('div',[_vm._v("-")]):_vm._e(),(item.state == 'MANAGED')?_c('v-text-field',{staticStyle:{"width":"50px","height":"100%","font-size":"0.875rem"},style:([
                  item.customMarginPercentage == false
                    ? { opacity: '0.15' }
                    : { opacity: '1' } ]),attrs:{"value":item.customMarginPercentageValue,"dense":"","min":0,"max":1000,"type":"number"},on:{"dblclick":function($event){item.customMarginPercentage = !item.customMarginPercentage},"change":function (selected) {
                    item.customMarginPercentageValue = selected;
                    item.notifyAboutChange = true;
                  }}}):_vm._e()],1),_c('td',[_c('v-select',{staticStyle:{"width":"140px","height":"100%","font-size":"0.875rem"},attrs:{"dense":"","items":_vm.states,"value":item.state,"return-object":""},on:{"change":function (selected) {
                    _vm.changeState(selected, item.id);
                    item.notifyAboutChange = true;
                  }}},[_c('template',{slot:"prepend-inner"},[(item.state == 'MANAGED')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cog-outline")])],1):_vm._e(),(item.state == 'MANUAL')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-wrench-outline")])],1):_vm._e(),(item.state == 'OMITTED')?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-cancel")])],1):_vm._e()])],2)],1),_c('td',[(item.groupList.length > 0)?_c('v-icon',{attrs:{"color":"green"},on:{"click":function($event){return _vm.groupEdit(item)}}},[_vm._v("mdi-link-box-variant-outline")]):_vm._e(),(item.groupList.length == 0)?_c('v-icon',{on:{"click":function($event){return _vm.groupEdit(item)}}},[_vm._v("mdi-link-box-variant-outline")]):_vm._e(),(item.notifyAboutChange == false)?_c('v-icon',{on:{"click":function($event){return _vm.saveOffer(
                    item.id,
                    item.state,
                    item.manualPrice,
                    item.manualStock,
                    item.customMarginPercentage,
                    item.customMarginPercentageValue
                  )}}},[_vm._v("mdi-content-save-move-outline")]):_vm._e(),(item.notifyAboutChange == true)?_c('v-icon',{attrs:{"color":"red darken-2"},on:{"click":function($event){_vm.saveOffer(
                    item.id,
                    item.state,
                    item.manualPrice,
                    item.manualStock,
                    item.customMarginPercentage,
                    item.customMarginPercentageValue
                  );
                  item.notifyAboutChange = false;}}},[_vm._v("mdi-content-save-move-outline")]):_vm._e()],1)],1)}),0)]},proxy:true}])})],1),(_vm.totalPages > 1)?_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-pagination',{attrs:{"length":_vm.totalPages,"total-visible":7},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),_c('offer-group-modal',{attrs:{"data":_vm.offerGroupData},model:{value:(_vm.showOfferGroupModal),callback:function ($$v) {_vm.showOfferGroupModal=$$v},expression:"showOfferGroupModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }