import axios from "axios";

export default {
  state: {
    token: localStorage.getItem("access_token") || null,
    expiresAt: localStorage.getItem("expires_at") || null,
  },

  getters: {
    loggedIn(state) {
      return state.token !== null && new Date() < new Date(state.expiresAt);
    },
  },

  mutations: {
    persistToken(state, token) {
      state.token = token;
    },

    persistExpiresAt(state, expiresAt) {
      state.expiresAt = expiresAt;
    },

    destroyToken(state) {
      state.token = null;
    },
  },

  actions: {
    retrieveToken(context, credentials) {
      delete axios.defaults.headers.common["Authorization"];
      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ROOT_URL + "/auth", {
            username: credentials.username,
            password: credentials.password,
            code: credentials.code,
          })
          .then((response) => {
            const token = response.data.token;
            const expiresAt = response.data.expiresAt;

            localStorage.setItem("access_token", token);
            localStorage.setItem("expires_at", expiresAt);
            context.commit("persistToken", token);
            context.commit("persistExpiresAt", expiresAt);

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + context.state.token;
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    destroyToken(context) {
      if (context.getters.loggedIn) {
        localStorage.removeItem("access_token");
        context.commit("destroyToken");
      }
    },

    authorizeToken(context) {
      console.log("auth check");
      return new Promise((resolve, reject) => {
        if (!context.getters.loggedIn) {
          reject();
        } else {
          resolve();
        }
      });
    },

    getActions(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/action/getActions?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrderPositions(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/orders/getAllPositions?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&allocOnly=" +
              payload.allocOnly,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrderPositionsWithSorting(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/orders/getAllPositions?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&allocOnly=" +
              payload.allocOnly +
              "&sortBy=" +
              payload.sortBy +
              "&dir=" +
              payload.dir,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getEnabledOrderPositions(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/orders/getAllEnabledPositions?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&allocOnly=" +
              payload.allocOnly,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrders(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/orders/getAllOrders?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrderPositionsWithSearchQuery(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/orders/searchPositions?searchquery=" +
              payload.searchquery +
              "&limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&allocOnly=" +
              payload.allocOnly,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changeOrderLineState(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/orders/changeOrderLineState?id=" +
              payload.id +
              "&state=" +
              payload.state,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProducts(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/getAllProducts?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&availableOnly=" +
              payload.availableOnly +
              "&selectedDistributors=" +
              payload.selectedDistributors,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProductsWithSearchQuery(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/searchProducts?searchquery=" +
              payload.searchquery +
              "&limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&availableOnly=" +
              payload.availableOnly +
              "&selectedDistributors=" +
              payload.selectedDistributors,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProductById(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/getProduct?id=" +
              payload.id,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getSimilarProductsByVendorId(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/getSimilarProductsById?id=" +
              payload.id,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProductsWarnings(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/getProductsWarningsByType?limit=" +
              payload.itemsPerPage +
              "&offset=" +
              payload.pageNumber +
              "&type=" +
              payload.type,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProperty(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/properties/getProperty?propertyName=" +
              payload.propertyName,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllProperties(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/properties/getAllProperties",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changeProperty(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/properties/changeProperty?id=" +
              payload.id +
              "&value=" +
              payload.value,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    evaluateProductWarningsOnDemand(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/action/evaluateProductWarningsOnDemand",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    changePassword(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ROOT_URL + "/user/changePassword", {
            oldPassword: payload.oldPassword,
            newPassword: payload.newPassword,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserDetails(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/user/details", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProductHistoryForChart(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/productHistory/getProductHistoryForChart?vendorId=" +
              payload.vendorId +
              "&provider=" +
              payload.provider,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getIsAllegroAuthenticated(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/allegro/isAuthenticated", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    authenticateAllegro(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/allegro/authenticate", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllOffers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/allegro/getAllOffers", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getDistributors(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/products/getDistributors", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllCorrectOffers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/allegro/getAllCorrectOffers",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllIncorrectOffers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/allegro/getAllIncorrectOffers",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllSemicorrectOffers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/allegro/getAllSemicorrectOffers",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAllEndedOffers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/allegro/getAllEndedOffers", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateNote(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/allegro/updateNote?offerId=" +
              payload.offerId +
              "&noteContent=" +
              payload.noteContent,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateWarningNotification(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/allegro/updateWarningNotification?offerId=" +
              payload.offerId,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRwcOrders(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwc_orders/getOrders?limit=" +
              payload.limit +
              "&offset=" +
              payload.offset,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    requestRwcOrderUpdate(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/action/rwcOrderUpdate", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    generateWfirmaReservation(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/action/generateWfirmaReservation?orderId=" +
              payload.orderId,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    generateTrelloCard(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/action/generateTrelloCard?orderId=" +
              payload.orderId,
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getAvailableLabels(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/properties/getAvailableLabels",
            {}
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getTrelloLabels(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/properties/getTrelloLabels", {})
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateTrelloLabels(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL + "/properties/updateTrelloLabels",
            payload
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUsers(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/user/list")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createShipmentFromOrder(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/shipping/createShipmentFromOrder?id=" +
              payload.id,
            payload.body
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getShipments(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/shipping/getShipments?limit=" +
              payload.limit +
              "&offset=" +
              payload.offset
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getLabel(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL + "/shipping/getLabel?id=" + payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isTrelloCardGenerated(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwc_orders/isTrelloCardGenerated?orderId=" +
              payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isReservationGenerated(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwc_orders/isReservationGenerated?orderId=" +
              payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isShippingGenerated(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwc_orders/isShippingGenerated?orderId=" +
              payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getOrder(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwc_orders/getOrder?id=" +
              payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    removeShipment(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/shipping/removeShipment?trackingNumber=" +
              payload.trackingNumber +
              "&shippingProvider=" +
              payload.shippingProvider
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    postShoperUpdateCsvFile(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      let formData = new FormData();

      formData.append("file", payload.file);

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL + "/action/uploadShoperCsv",
            formData,
            { headers: { "Content-Type": "multipart/form-data" } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getCsvWfirmaReport(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/wfirmareport/generate?type=" +
              payload.type +
              "&year=" +
              payload.year +
              "&month=" +
              payload.month
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getShipmentStatus(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/shipping/getShipmentStatus?trackingNumber=" +
              payload.trackingNumber +
              "&shippingProvider=" +
              payload.shippingProvider
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getWfirmaAvailableReports(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/wfirmareport/list/available")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getProductsList(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/products/list?sortingType=" +
              payload.sortingType +
              "&availableOnly=" +
              payload.availableOnly +
              "&providers=" +
              payload.providers +
              "&searchQuery=" +
              payload.searchQuery +
              "&limit=" +
              payload.limit +
              "&offset=" +
              payload.offset
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    editUser(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .put(
            process.env.VUE_APP_ROOT_URL + "/user/edit?id=" + payload.id,
            payload.body
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addUser(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ROOT_URL + "/user/add", payload.body)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deleteUser(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .delete(
            process.env.VUE_APP_ROOT_URL + "/user/delete?id=" + payload.id
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getReports(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/wfirmareport/list?limit=" +
              payload.limit +
              "&offset=" +
              payload.offset
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getReportData(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/wfirmareport?id=" + payload.id)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getStatisticsData(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/statistics/short")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getLastSuccesses(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/lastsuccess")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getRwcOffers(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(
            process.env.VUE_APP_ROOT_URL +
              "/rwcOffers/list?states=" +
              payload.states +
              "&limit=" +
              payload.limit +
              "&offset=" +
              payload.offset +
              "&sortingType=" +
              payload.sortingType +
              "&searchQuery=" +
              payload.searchQuery +
              "&groupOnly=" +
              payload.groupOnly
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceRwcOfferUpdate(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/rwcOffers/forceUpdate")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceRwcOfferAdd(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/rwcOffers/forceAdd")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    forceRwcOfferCalculation(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .get(process.env.VUE_APP_ROOT_URL + "/rwcOffers/forceCalculate")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    saveRwcOffer(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .patch(
            process.env.VUE_APP_ROOT_URL +
              "/rwcOffers?id=" +
              payload.id +
              "&manualPrice=" +
              payload.manualPrice +
              "&manualStock=" +
              payload.manualStock +
              "&state=" +
              payload.state +
              "&isCustomMargin=" +
              payload.isCustomMargin +
              "&customMarginValue=" +
              payload.customMarginValue
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    enable2fa(context) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(process.env.VUE_APP_ROOT_URL + "/user/enable2fa")
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    saveGroup(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL +
              "/rwcOffers/" +
              payload.id +
              "/updateGroups",
            payload.body,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getWfirmaStocks(context, payload) {
      if (context.state.token != null) {
        axios.defaults.headers.common["Authorization"] =
          "Bearer " + context.state.token;
      }

      return new Promise((resolve, reject) => {
        axios
          .post(
            process.env.VUE_APP_ROOT_URL + "/products/wfirmaProducts",
            payload.body,
            { headers: { "Content-Type": "application/json" } }
          )
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
};
