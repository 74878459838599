<template>
  <v-dialog v-model="show" width="1200px" height v-if="data != null" persistent>
    <v-dialog v-model="newGroupAddModal" width="600px" persistent>
      <v-card class="pa-4">
        <div class="overline mb-2" style="font-size: 15px !important">
          Dodaj nową grupę
        </div>
        <v-form v-model="newGroupIsValid">
          <v-col>
            <v-text-field
              dense
              v-model="newGroupName"
              outlined
              label="Nazwa grupy"
              :rules="notEmptyRule"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              v-model="newGroupRequiredCount"
              outlined
              label="Wymagana liczba sztuk"
              :rules="requiredPositiveNumberOnlyRule"
              clearable
              type="number"
            ></v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="addGroup()"
              class="mr-1"
              :disabled="!newGroupIsValid"
              >Dodaj</v-btn
            >
            <v-btn outlined @click="cancelAddGroup()">Anuluj</v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editGroupModal" width="600px" persistent>
      <v-card class="pa-4">
        <div class="overline mb-2" style="font-size: 15px !important">
          Edytuj grupę
        </div>
        <v-form v-model="editGroupIsValid">
          <v-col>
            <v-text-field
              dense
              v-model="editGroupName"
              outlined
              label="Nazwa grupy"
              :rules="notEmptyRule"
              clearable
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
              dense
              v-model="editGroupRequiredCount"
              outlined
              label="Wymagana liczba sztuk"
              :rules="requiredPositiveNumberOnlyRule"
              clearable
              type="number"
            ></v-text-field>
          </v-col>
          <v-col class="text-right">
            <v-btn
              color="primary"
              @click="editGroup()"
              class="mr-1"
              :disabled="!editGroupIsValid"
              >Zatwierdź</v-btn
            >
            <v-btn outlined @click="cancelEditGroup()">Anuluj</v-btn>
          </v-col>
        </v-form>
      </v-card>
    </v-dialog>
    <v-dialog v-model="newItemAddModal" width="600px" persistent>
      <v-card class="pa-4">
        <div class="overline mb-2" style="font-size: 15px !important">
          Dodaj element do grupy
          <span v-if="this.data.groupList[selectedGroupIndex] != undefined">
            {{ this.data.groupList[selectedGroupIndex].name }}
          </span>
        </div>
        <v-form v-model="newItemIsValid">
          <v-col>
            <v-text-field
              dense
              v-model="newItemName"
              outlined
              label="Nazwa*"
              :rules="notEmptyRule"
              clearable
              required
            />
            <v-text-field
              dense
              v-model="newItemWarehouseId"
              outlined
              label="Wfirma ID*"
              :rules="requiredNumberOnlyRule"
            />
            <v-text-field
              dense
              v-model="newItemMpn"
              outlined
              label="Kod producenta"
            >
              <v-tooltip bottom slot="append">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <span
                  >W przypadku nie wypełnienia tego pola kod producenta zostanie
                  pobrany z produktu wfirma</span
                >
              </v-tooltip>
            </v-text-field>
            <v-select
              class="shrink pa-0 ma-0"
              hide-details="true"
              v-model="newItemSelectedDistributors"
              :items="distributorsList"
              dense
              outlined
              label="Dystrybutorzy"
              multiple
              :rules="atLeastOneSelectedRule"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="require(`@/assets/icons/${item.toLowerCase()}.png`)"
                  height="16px"
                  width="16px"
                  style="margin-right: 5px"
                />
                {{ item }}
              </template>
            </v-select>
          </v-col>
        </v-form>
        <v-col class="text-right">
          <v-btn
            color="primary"
            @click="addItem()"
            class="mr-1"
            :disabled="!newItemIsValid"
            >Dodaj</v-btn
          >
          <v-btn outlined @click="cancelAddItem()">Anuluj</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="editItemModal" width="600px" persistent>
      <v-card class="pa-4">
        <div class="overline mb-2" style="font-size: 15px !important">
          Edytuj element
          <span v-if="this.data.groupList[selectedGroupIndex] != undefined">
            {{ this.data.groupList[selectedGroupIndex].name }}
          </span>
        </div>
        <v-form v-model="editItemIsValid">
          <v-col>
            <v-text-field
              dense
              v-model="editItemName"
              outlined
              label="Nazwa"
              :rules="notEmptyRule"
              clearable
            />
            <v-text-field
              dense
              v-model="editItemWarehouseId"
              outlined
              label="Wfirma ID"
              :rules="requiredNumberOnlyRule"
            />
            <v-text-field
              dense
              v-model="editItemMpn"
              outlined
              label="Kod producenta"
            >
              <v-tooltip bottom slot="append">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                </template>
                <span
                  >W przypadku nie wypełnienia tego pola kod producenta zostanie
                  pobrany z produktu wfirma</span
                >
              </v-tooltip>
            </v-text-field>
            <v-select
              class="shrink pa-0 ma-0"
              hide-details="true"
              v-model="editItemSelectedDistributors"
              :items="distributorsList"
              dense
              outlined
              label="Dystrybutorzy"
              multiple
              :rules="atLeastOneSelectedRule"
            >
              <template v-slot:item="{ item }">
                <img
                  :src="require(`@/assets/icons/${item.toLowerCase()}.png`)"
                  height="16px"
                  width="16px"
                  style="margin-right: 5px"
                />
                {{ item }}
              </template>
            </v-select>
          </v-col>
        </v-form>
        <v-col class="text-right">
          <v-btn
            color="primary"
            @click="editItem()"
            class="mr-1"
            :disabled="!editItemIsValid"
            >Zatwierdź</v-btn
          >
          <v-btn outlined @click="cancelEditItem()">Anuluj</v-btn>
        </v-col>
      </v-card>
    </v-dialog>
    <v-card class="pa-4">
      <div class="overline mb-2" style="font-size: 16px !important">
        Grupy komponentów dla {{ this.data.name }}
      </div>
      <div v-if="data.groupList.length == 0">Brak zdefinowanych grup!</div>
      <div v-if="data.groupList.length > 0">
        <div
          class="my-1 mt-6"
          v-for="(group, groupIndex) in data.groupList"
          :key="group.id"
        >
          <div class="my-1">
            <v-toolbar dense :elevation="0" color="#ededed">
              {{ group.name }}
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-chip small class="ml-2"
                      >{{ group.requiredCount }} szt.</v-chip
                    >
                  </span>
                </template>
                <span>wymagana liczba sztuk</span>
              </v-tooltip>
              <v-spacer></v-spacer>

              <v-icon @click="showItemAdd(groupIndex)" class="mr-1"
                >mdi-plus-box-multiple</v-icon
              >
              <v-icon @click="showEditGroup(groupIndex)" class="mr-1"
                >mdi-pencil</v-icon
              >
              <v-icon @click="removeGroup(groupIndex)"
                >mdi-trash-can-outline</v-icon
              >
            </v-toolbar>
          </div>
          <v-simple-table
            dense
            style="
              margin-top: -6px;
              border-left: solid #ededed;
              border-bottom: solid #ededed;
              border-right: solid #ededed;
            "
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left" width="200px">Nazwa</th>
                  <th class="text-left" width="100px">Kod wfirma</th>
                  <th class="text-left" width="180px">Kod producenta</th>
                  <th class="text-center" width="120px">Dystrybutorzy</th>
                  <th class="text-center" width="30px">Akcje</th>
                  <th class="text-center" v-if="showStocks" width="100px">
                    Stan
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, itemIndex) in group.itemList" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>
                    <a
                      :href="`https://wfirma.pl/goods/view/${item.warehouseId}`"
                      target="_blank"
                      >{{ item.warehouseId }}</a
                    >
                  </td>
                  <td>{{ item.mpn }}</td>
                  <td class="text-center">
                    <img
                      v-for="distributor in item.selectedDistributors"
                      :key="distributor"
                      :src="
                        require(`@/assets/icons/${distributor.toLowerCase()}.png`)
                      "
                      height="14px"
                      width="14px"
                      style="margin-right: 5px"
                    />
                  </td>
                  <td class="text-center">
                    <v-icon small @click="showItemEdit(groupIndex, itemIndex)"
                      >mdi-pencil</v-icon
                    >
                    <v-icon small @click="removeItem(groupIndex, itemIndex)"
                      >mdi-trash-can-outline</v-icon
                    >
                  </td>
                  <td class="text-center" v-if="showStocks">
                    {{ getForWarehouseId(item.warehouseId) }}
                  </td>
                </tr>
                <tr v-if="showStocks" style="background-color: #ededed">
                  <td />
                  <td />
                  <td />
                  <td />
                  <td class="text-right"><b>DOSTĘPNE:</b></td>
                  <td class="text-center" color="#ededed">
                    <b
                      >{{ getSum(groupIndex) }} / {{ group.requiredCount }} =
                      {{ Math.floor(getSum(groupIndex) / group.requiredCount) }}
                      szt.</b
                    >
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </div>
      </div>
      <v-row no-gutters class="mt-5 ml-2">
        <v-col class="text-left">
          <v-btn outlined class="mr-1" @click="showGroupAdd()"
            >Dodaj grupę</v-btn
          >
          <v-btn outlined class="mr-1" @click="getStocks()"
            >Sprawdź stany</v-btn
          >
        </v-col>
        <v-col class="text-right">
          <v-btn @click="saveGroups()" class="mr-1" color="primary"
            >Zapisz</v-btn
          >
          <v-btn
            outlined
            @click="
              () => {
                Object.assign(this.$data, this.$options.data());
                $emit('input', false);
              }
            "
            class="mr-1"
            >Zamknij</v-btn
          >
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  methods: {
    addGroup() {
      this.data.groupList.push({
        id: crypto.randomUUID(),
        name:
          this.newGroupName === undefined
            ? "Grupa " + (this.data.groupList.length + 1)
            : this.newGroupName.trim(),
        requiredCount: this.newGroupRequiredCount,
        itemList: [],
      });
      this.newGroupAddModal = false;
    },
    cancelAddGroup() {
      this.newGroupAddModal = false;
    },
    showEditGroup(groupIndex) {
      this.selectedGroupIndex = groupIndex;
      this.editGroupName = this.data.groupList[groupIndex].name;
      this.editGroupRequiredCount =
        this.data.groupList[groupIndex].requiredCount;
      this.editGroupModal = true;
    },
    editGroup() {
      this.data.groupList[this.selectedGroupIndex].name =
        this.editGroupName.trim();
      this.data.groupList[this.selectedGroupIndex].requiredCount =
        this.editGroupRequiredCount.trim();
      this.editGroupModal = false;
    },
    cancelEditGroup() {
      this.editGroupModal = false;
    },
    removeGroup(groupIndex) {
      this.data.groupList.splice(groupIndex, 1);
    },
    showGroupAdd() {
      this.newGroupAddModal = true;
    },
    showItemAdd(groupIndex) {
      this.newItemAddModal = true;
      this.selectedGroupIndex = groupIndex;
      this.newItemSelectedDistributors = ["WFIRMA"];
    },
    addItem() {
      this.data.groupList[this.selectedGroupIndex].itemList.push({
        id: crypto.randomUUID(),
        name: this.newItemName.trim(),
        warehouseId: this.newItemWarehouseId.trim(),
        mpn:
          this.newItemMpn == undefined
            ? this.newItemMpn
            : this.newItemMpn.trim(),
        selectedDistributors: this.newItemSelectedDistributors,
      });
      this.newItemName = undefined;
      this.newItemWarehouseId = undefined;
      this.newItemMpn = undefined;
      this.newItemSelectedDistributors = ["WFIRMA"];
      this.newItemAddModal = false;
      this.showStocks = false;
    },
    cancelAddItem() {
      this.newItemAddModal = false;
    },
    removeItem(groupIndex, itemIndex) {
      this.data.groupList[groupIndex].itemList.splice(itemIndex, 1);
    },
    showItemEdit(groupIndex, itemIndex) {
      this.selectedGroupIndex = groupIndex;
      this.selectedItemIndex = itemIndex;
      this.editItemId = this.data.groupList[groupIndex].itemList[itemIndex].id;
      this.editItemName =
        this.data.groupList[groupIndex].itemList[itemIndex].name;
      this.editItemWarehouseId =
        this.data.groupList[groupIndex].itemList[itemIndex].warehouseId;
      this.editItemMpn =
        this.data.groupList[groupIndex].itemList[itemIndex].mpn;
      this.editItemSelectedDistributors =
        this.data.groupList[groupIndex].itemList[
          itemIndex
        ].selectedDistributors;
      this.editItemModal = true;
    },
    editItem() {
      this.data.groupList[this.selectedGroupIndex].itemList[
        this.selectedItemIndex
      ].id = this.editItemId.trim();
      this.data.groupList[this.selectedGroupIndex].itemList[
        this.selectedItemIndex
      ].name = this.editItemName.trim();
      this.data.groupList[this.selectedGroupIndex].itemList[
        this.selectedItemIndex
      ].warehouseId = this.editItemWarehouseId.trim();
      (this.data.groupList[this.selectedGroupIndex].itemList[
        this.selectedItemIndex
      ].mpn =
        this.editItemMpn == undefined
          ? this.editItemMpn
          : this.editItemMpn.trim()),
        (this.data.groupList[this.selectedGroupIndex].itemList[
          this.selectedItemIndex
        ].selectedDistributors = this.editItemSelectedDistributors);
      this.editItemModal = false;
      this.showStocks = false;
    },
    cancelEditItem() {
      this.editItemModal = false;
    },
    saveGroups() {
      this.$store
        .dispatch("saveGroup", {
          id: this.data.id,
          body: JSON.stringify(this.data.groupList),
        })
        .then(() => {
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "success",
            text: "Zapisano!",
          });
          this.$emit("input", false);
        })
        .catch((error) => {
          error.response.data.forEach((item) => {
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: item,
            });
          });
        });
    },
    getStocks() {
      var arr = [];
      this.data.groupList.forEach((group) => {
        group.itemList.forEach((item) => {
          arr.push(item);
        });
      });
      this.$store
        .dispatch("getWfirmaStocks", {
          body: JSON.stringify(arr),
        })
        .then((success) => {
          this.stocksData = success.data;
          this.showStocks = true;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Wystąpił problem z pobieraniem stanów",
          });
        });
    },
    getForWarehouseId(warehouseId) {
      if (this.stocksData != undefined) {
        var product = this.stocksData.find((p) => p.warehouseId == warehouseId);
        if (product != undefined) {
          return product.sum + " szt.";
        } else {
          return "Nie znaleziono!";
        }
      }
    },
    getSum(groupIndex) {
      var num = 0;
      this.data.groupList[groupIndex].itemList.forEach((item) => {
        var product = this.stocksData.find(
          (p) => p.warehouseId == item.warehouseId
        );
        if (product != undefined) {
          num += product.sum;
        }
      });
      return num;
    },
  },
  props: {
    value: Boolean,
    data: null,
  },
  data() {
    return {
      newGroupAddModal: false,
      editGroupModal: false,
      newItemAddModal: false,
      editItemModal: false,
      newGroupIsValid: false,
      newGroupName: undefined,
      newGroupRequiredCount: 0,
      editGroupName: undefined,
      editGroupRequiredCount: 0,
      editGroupIsValid: false,
      newItemIsValid: false,
      newItemName: undefined,
      newItemWarehouseId: undefined,
      newItemMpn: undefined,
      newItemSelectedDistributors: [],
      editItemIsValid: false,
      editItemId: undefined,
      editItemName: undefined,
      editItemWarehouseId: undefined,
      editItemMpn: undefined,
      editItemSelectedDistributors: [],
      selectedGroupIndex: 0,
      selectedItemIndex: 0,
      showStocks: false,
      stocksData: undefined,
      distributorsList: [
        "AB",
        "ACC",
        "ACTION",
        "ALSO",
        "INGRAM",
        "TECHDATA",
        "WFIRMA",
      ],
      notEmptyRule: [(v) => (v && v.length > 0) || "Pole wymagane!"],
      requiredNumberOnlyRule: [
        (v) => {
          if (v && v.length > 0 && !isNaN(parseFloat(v))) return true;
          return "Pole wymagane, tylko liczby!";
        },
      ],
      atLeastOneSelectedRule: [
        (v) => v.length > 0 || "Musisz wybrać co najmniej jedną opcję!",
      ],
      requiredPositiveNumberOnlyRule: [
        (v) => {
          if (v && v.length > 0 && !isNaN(parseFloat(v)) && v >= 0) return true;
          return "Pole wymagane, tylko pozytywne liczby!";
        },
      ],
    };
  },
  computed: {
    show: {
      get() {
        return this.value;
      },
      set(value) {
        Object.assign(this.$data, this.$options.data());
        this.$emit("input", value);
      },
    },
  },
};
</script>
<style scoped>
a {
  color: #ffffff;
  text-decoration: none;
}
.material-icons {
  vertical-align: middle;
}
</style>
