
<script>
import { Line } from 'vue-chartjs'

export default {
    extends: Line,
    props: {
        x: Array(String),
        y: Array(String),
        unit: String
    },
    name: 'ProductHistoryPrice.vue',

    mounted() {
        this.renderChart(
        {
            labels: this.x,
            datasets: [
            {
                label: this.unit,
                data: this.y,
                backgroundColor: "transparent",
                borderColor: "rgba(1, 116, 188, 0.50)",
                pointBackgroundColor: "rgba(171, 71, 188, 1)"
            }
            ]
        },
        {
            responsive: true,
            maintainAspectRatio: false,
            tooltipTemplate: "<%= zl %>",
            title: {
                display: true,
            }
        }
        );
    }
}

</script>
