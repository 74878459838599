<template>
  <div class="orders">
    <v-tabs
      color="#9e9e9e"
      background-color="#f5f5f5"
    >
    <v-tab @click="view='orders'">
      Zamówienia
    </v-tab>
    <v-tab @click="view='products'">
      Produkty
    </v-tab>
      <v-tab @click="view='reservedandenabled'">
      Zarezerwowane i włączone
    </v-tab>
    </v-tabs>

    <div v-if="view == 'orders'">
      <order-table/>
    </div>
    <div v-else-if="view == 'products'">
      <order-line-table/>
    </div>
    <div v-else-if="view == 'reservedandenabled'">
      <reserved-and-enabled-table/>
    </div>
  </div>
</template>

<script>
import OrderLineTable from '../components/reservationsTables/OrderLineTable.vue';
import OrderTable from '../components/reservationsTables/OrderTable.vue';
import ReservedAndEnabledTable from '../components/reservationsTables/ReservedAndEnabledTable.vue'

export default {
  components: { OrderLineTable, OrderTable, ReservedAndEnabledTable },
  data() {
  
    return {
      view: 'orders',
    }
  }
};
</script>
