<template>
    <div>
        <v-tabs color="#9e9e9e"
                background-color="#f5f5f5"
                v-model="active_tab">
            <v-tab @click="$router.back()">
                <v-icon>mdi-arrow-left</v-icon>
            </v-tab>
            <v-tab @click="view='ProductDetails'">Aktualna dostępność</v-tab>
            <v-tab @click="view='ProductCharts'">Dane historyczne</v-tab>
        </v-tabs>
        <div v-if="view == 'ProductDetails'">
            <product-details/>
        </div>
        <div v-else-if="view == 'ProductCharts'">
            <product-charts/>
        </div>
    </div>
</template>

<script>

import ProductDetails from '../components/productDetails/ProductDetails.vue';
import ProductCharts from '../components/productDetails/ProductCharts.vue';

export default {
  components: { ProductDetails, ProductCharts },
    name: 'Product',
    data() {
        return {
            id: this.$route.params.id,
            active_tab: 1,
            view: 'ProductDetails',
        }
    },
    mounted() {
        this.selectedTab = '1';
        this.getProduct();
    },
}
</script>
