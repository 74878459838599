<template>
  <v-app class="grey lighten-4">
    <Navbar />
    <v-main class="px-4 grey lighten-4">
      <div class="pl-13">
        <router-view ></router-view>
      </div>
    </v-main>

    <template>
    <div>

      <v-snackbar
        v-for="(snackbar, index) in snackbars.filter(s => s.showing)"
        :key="snackbar.text + Math.random()"
        v-model="snackbar.showing"
        :timeout="snackbar.timeout"
        :color="snackbar.color"
        :style="`bottom: ${index * 95 + 5}px`"
        :max-width="5"
        right
        >


        <v-row class="align-center">
          <v-col class="align-center subtitle-1" md=10>
            {{snackbar.text}}
          </v-col>
          <v-col md=2>
          <v-btn icon @click="snackbar.showing = false" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          </v-col>
        </v-row>
      </v-snackbar>

    </div>
    </template>

  </v-app>
</template>

<script>
import Navbar from '@/components/Navbar'
import { mapState } from 'vuex';

export default {
  name: 'App',
  components: { Navbar },
  created() {
    document.title = "RWCentral";
  },
  computed: {
    ...mapState({
      currentUser: state => state.users.currentUser, 
      snackbars: state => state.snackbar.snackbars
    })
  },
  data: () => ({
    //
  }),
};
</script>
