<template>
    <v-container class="mx-3 mt-2" fill-height>
        <div v-if='loading == true'>
            <v-layout justify-center align-center>
                <v-progress-circular
                    :size="70"
                    :width="7"
                    color="grey"
                    indeterminate
                ></v-progress-circular>
            </v-layout>
            </div>
            <!-- CHIPS -->
        <v-row>
        <v-col>
            <div>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-chip v-if="data.availableInStock>0" 
                            v-on="on"
                            class="mx-1"
                            color="green"
                            link
                            small
                            outlined
                        >DOSTĘPNY</v-chip>
                    </template>
                    <span>dostępne {{data.availableInStock}} szt.</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-chip v-if="data.availableInDeliv>0" 
                            v-on="on"
                            class="mx-1"
                            color="orange"
                            link
                            small
                            outlined
                        >DOSTĘPNY W DOSTAWIE</v-chip>   
                    </template>
                    <span>w dostawie {{data.availableInDeliv}} szt.</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-chip v-if="data.reservedInStock>0" 
                            v-on="on"
                            class="mx-1"
                            color="black"
                            link
                            small
                            outlined
                            >ZAREZERWOWANY W MAGAZYNIE</v-chip>        
                    </template>
                    <span>zarezerwowane w magazynie {{data.reservedInStock}} szt.</span>
                </v-tooltip>     
                <v-tooltip bottom>
                    <template #activator="{ on }">
                        <v-chip v-if="data.reservedInDeliv>0" 
                            v-on="on"
                            class="mx-1"
                            color="blue"
                            link
                            small
                            outlined
                        >ZAREZERWOWANY W DOSTAWIE</v-chip> 
                    </template>
                    <span>zarezerwowane w dostawie {{data.reservedInDeliv}} szt.</span>
                </v-tooltip>              
            </div>
        </v-col>
        </v-row>
        <v-row>
        <v-col class="my-n4">
            <v-divider/>
        </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="12">
                <h3>
                    <b>{{data.name.toUpperCase()}}</b>
                </h3>
            </v-col>
            <v-col cols="12" md="12" class="my-n5">
                <div>
                    <a :href="getLink(data.provider)+data.vendorId" target="_blank">
                        <v-icon small>mdi-open-in-new</v-icon>
                    </a>
                    <span @click="copyText(data.provider)" class="caption mx-1"><b>DOSTAWCA:</b> {{data.provider}}</span>
                    <span @click="copyText(data.price)" class="caption mx-1"><b>CENA:</b> {{data.price}} {{data.currency}}</span>
                    <span @click="copyText(data.manufacturerPartNumber)" class="caption mx-1"><b>MP/N:</b> {{data.manufacturerPartNumber}}</span>
                    <span @click="copyText(data.createdAt)" class="caption mx-1"><b>POBRANO:</b> {{data.createdAt}}</span>
                </div>
            </v-col>
            <v-col cols="12" md="12" class="my-n1">
                <div>
                    <span v-if="data.availableInStock!=null" class="caption mx-1"><v-chip x-small>DOSTĘPNE: {{data.availableInStock}}</v-chip></span>
                    <span v-if="data.availableInDeliv!=null" class="caption mx-1"><v-chip x-small>W DOSTAWIE: {{data.availableInDeliv}}</v-chip></span>
                    <span v-if="data.reservedInStock!=null" class="caption mx-1"><v-chip x-small>ZAREZERWOWANE: {{data.reservedInStock}}</v-chip></span>
                    <span v-if="data.reservedInDeliv!=null" class="caption mx-1"><v-chip x-small>ZAREZERWOWANE W DOSTAWIE: {{data.reservedInDeliv}}</v-chip></span>
                </div>
            </v-col>
        </v-row>
        <v-row>
        <v-col >
            <v-divider/>
        </v-col>
        </v-row>
        <v-row v-for="product in similarProducts" :key="product.id">
            <v-col cols="12" md="12" class="my-n5">
                <div>
                    <a :href="getLink(product.provider)+product.vendorId" target="_blank">
                        <v-icon small>mdi-open-in-new</v-icon>
                    </a>
                    <span @click="copyText(data.provider)" class="caption mx-1"><b>DOSTAWCA:</b> {{product.provider}}</span>
                    <span @click="copyText(data.provider)" class="caption mx-1"><b>CENA:</b> {{product.price}} {{product.currency}}</span>
                    <span @click="copyText(data.manufacturerPartNumber)" class="caption mx-1"><b>MP/N:</b> {{product.manufacturerPartNumber}}</span>
                    <span @click="copyText(data.createdAt)" class="caption mx-1"><b>POBRANO:</b> {{product.createdAt}}</span>
                </div>
            </v-col>
            <v-col cols="12" md="12" class="my-n1">
                <div>
                    <span v-if="product.availableInStock!=null" class="caption mx-1"><v-chip x-small>DOSTĘPNE: {{product.availableInStock}}</v-chip></span>
                    <span v-if="product.availableInDeliv!=null" class="caption mx-1"><v-chip  x-small>W DOSTAWIE: {{product.availableInDeliv}}</v-chip></span>
                    <span v-if="product.reservedInStock!=null" class="caption mx-1"><v-chip x-small>ZAREZERWOWANE: {{product.reservedInStock}}</v-chip></span>
                    <span v-if="product.reservedInDeliv!=null" class="caption mx-1"><v-chip x-small>ZAREZERWOWANE W DOSTAWIE: {{product.reservedInDeliv}}</v-chip></span>
                </div>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>

export default {
    name: 'ProductDetails',
    methods: {
        sumAvability(product, similarProducts, type){
            var avability = 0;
            if(type=="availableInStock"){
                avability += product.availableInStock;
                if(similarProducts){
                    for (var prod of similarProducts){
                        avability += prod.availableInStock;
                    }
                }
                return avability;
            }
        },
        copyText(text){
            var el = document.createElement('input');
            document.body.appendChild(el);
            el.value = text;
            el.select();
            document.execCommand("copy");
            el.remove();
        },
        getLink(distributor){
            for(var i=0;i<this.searchlinks.length;i++){
                var link = this.searchlinks[i];
                if(link['provider'] == distributor){
                    return link['link'];
                }
            }
        },
        getProduct(){
         this.$store
          .dispatch("getProductById", {
            id: this.id,
          })
          .then((response) => {
                this.data = response.data;
                this.loading = false;
                this.getSimilarProducts(response.data.id);
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Something went wrong with getProduct()",
            });
          });
        },
        getSimilarProducts(id){
         this.$store
          .dispatch("getSimilarProductsByVendorId", {
            id: id,
          })
          .then((response) => {
                this.similarProducts = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Something went wrong with getSimilarProductsByVendorId()",
            });
          });
        },           
    },
    data() {
        return {
            id: this.$route.params.id,
            data: null,
            similarProducts: null,
            loading: true,
            searchlinks: [{
                provider: 'INGRAM',
                link: 'https://www.ingrammicro24.com/pl/catalog/?search=',
            },
            {
                provider: 'WFIRMA',
                link: 'https://wfirma.pl/goods/view/',
            },
            {
                provider: 'TECHDATA',
                link: 'https://intouch.techdata.com/InTouch/MVC/Product/ProductDetails?ProductId=',
            },
            {
                provider: 'ALSO',
                link: 'https://www.also.com/ec/cms5/5410/ProductDetailData.do?prodId=',
            },
            {
                provider: 'ACTION',
                link: 'https://is3.action.pl/product/id-',
            },
            {
                provider: 'AB',
                link: 'https://www.abonline.pl/offer/pl/0/#/product/?pr=',
            }]
        }
    },
    mounted() {
        this.getProduct();
    },
}
</script>
