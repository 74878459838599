 <template>
  <div class="orderTable">
    <reservation-modal v-model="showReservationModal" :data="reservationModalData" />
    <v-data-table
      dense
      height="720"
      fixed-header
      :headers="headers"
      :items="content"
      class="elevation-1"
      :loading="loading"
      :options.sync="options"
      :server-items-length="totalItems"
      :footer-props="footerProps"
      :header-props="{ sortIcon: null }"
    >

      <template v-slot:item.provider="{ item }">
          <img :src="require(`@/assets/icons/${item.provider.toLowerCase()}.png`)" height="16px" width="16px" style="margin-right:5px; text-align: center; vertical-align: middle;">
      </template>

      <template v-slot:item.creationTime="{ item }">
        <span class="text-center">{{ getDate(item.creationTime) }}</span><br>
      </template>

      <template v-slot:item.orderTotalNetAmount="{ item }">
          {{ item.orderTotalNetAmount }} {{ item.orderCurrency }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon medium @click="showReservationModal=true, reservationModalData=item">mdi-magnify</v-icon>
      </template>
    </v-data-table>
  </div>
</template>



<script>
import ReservationModal from '../ReservationModal.vue';

export default {
  components: { ReservationModal },
  methods: {
    getDate(dateTime){
        let temp = dateTime.split(" ");
        return temp[0];
    },
    openModal(data) {
      this.modalData = data;
      this.modalVisible = true;
    },
    getOrders() {
      this.loading = true;
      const { page, itemsPerPage } = this.options;
      let pageNumber = itemsPerPage * (page - 1);

      this.$store
        .dispatch("getOrders", {
          itemsPerPage: itemsPerPage,
          pageNumber: pageNumber,
        })
        .then((response) => {
          this.content = response.data.content;
          this.loading = false;
          this.totalItems = response.data.totalElements;
          this.totalPages = response.data.totalPages;
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with getOrderPositions()",
          });
        });
    },
  },
  mounted() {
    this.getOrders();
  },

  name: "Orders",
  data() {
    return {
      totalItems: 0,
      search: "",
      showReservationModal: false,
      reservationModalData: null,
      showAllocatedOnly: false,
      loading: true,
      options: { page: 1 },
      content: [],
      footerProps: { "items-per-page-options": [20, 50, 100, 200, 300] },
      headers: [
        {
          id: "1",
          text: "DIS",
          align: "center",
          sortable: false,
          value: "provider",
          width: "20px"
        },
        {
          id: "2",
          text: "Created at",
          align: "start",
          value: "creationTime",
          width: "150px",
        },
        {
          id: "3",
          text: "ID",
          align: "start",
          sortable: false,
          value: "externalId",
        },
        { id: "4", text: "Reference number", value: "referenceNumber" },
        { id: "5", text: "Net amount", value: "orderTotalNetAmount", align: "right" },
        { id: "6", text: "Status", value: "status", align: "left" },
        { id: "7", text: "Action", value: "action", align: "center" },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        this.getOrders();
      },
    },
    deep: true,
  },
};
</script>
