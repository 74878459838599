<template>
   <v-container fluid fill-height class="mt-16">
      <v-layout align-center justify-center>
          <v-flex xs12 sm8 md3>
               <v-container class="text-center text-xl-h3">
                  <span class="font-weight-light">RW</span>
                  <span>Central</span>
               </v-container>

             <v-form class="pa-0 mt-4" @submit.prevent="login">
               <v-text-field outlined 
                  dense
                  v-model="username"
                  label="Nazwa użytkownika"
                  required
               ></v-text-field>
               <v-text-field outlined 
                  dense
                  v-model="password"
                  label="Hasło"
                  type="password"
                  required
               ></v-text-field>
               <v-text-field outlined 
                  dense
                  v-model="code"
                  label="Kod 2FA"
               ></v-text-field>

               <v-container class="text-right px-0 mx-0">
                  <v-btn type="submit" outlined color="green" :loading="loading" @click="remove">
                  <v-icon left>mdi-login</v-icon>
                  ZALOGUJ
                  </v-btn>
               </v-container>
            </v-form>
          </v-flex>
      </v-layout>
   </v-container>
</template>

<script>
export default {
   name: 'Login',   
   data() {
      return {
         loading: false,
         username: '',
         password: '',
         code: '',
      }
   },
   methods: {
      async remove () {
        this.loading = true
        await new Promise(resolve => setTimeout(resolve, 3000))
        this.loading = false
      },
      login() {
         this.$store.dispatch('retrieveToken', {
            username: this.username,
            password: this.password,
            code: this.code
         }) // eslint-disable-next-line no-unused-vars
            .then(response => { 
               this.$router.push({name: 'dashboard'})
            }) // eslint-disable-next-line no-unused-vars
            .catch(error => {
               this.$store.dispatch('snackbar/setSnackbar',{color:'error', text: "Błąd logowania"})
            })
      }
    },
  }
</script>
