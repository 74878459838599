<template>
  <div class="orders">
    <v-tabs
      color="#9e9e9e"
      background-color="#f5f5f5"
    >
    <v-tab @click="view='priceWarnings'">
      Ostrzeżenia cenowe
    </v-tab>

    <v-tab @click="view='presenceWarnings'">
      Ostrzeżenia produktowe
    </v-tab>

    </v-tabs>

    <div v-if="view == 'priceWarnings'">
      <price-warning-table/>
    </div>

    <div v-if="view == 'presenceWarnings'">
      <presence-warning-table/>
    </div>
  </div>
</template>

<script>

import PriceWarningTable from '../components/warningTables/PriceWarningTable.vue';
import PresenceWarningTable from '../components/warningTables/PresenceWarningTable.vue';

export default {
  components: { PriceWarningTable, PresenceWarningTable },
  data() {
  
    return {
      view: 'priceWarnings',
    }
  }
};
</script>
