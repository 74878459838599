<template>
  <div>
        <v-row class="my-5" v-if='offersReady == false && dataRequested == true' align="center" justify="center">
        <v-progress-linear
            height="10"
            color="#9E9E9E"
            indeterminate
        ></v-progress-linear>
        </v-row>
        <div v-if="offersReady==true">

            <v-simple-table>
                <template v-slot:default>
                <thead>
                    <tr>
                    <th class="text-left" style="width:75px">
                        Offer ID
                    </th>
                    <th class="text-left" style="width:450px">
                        Offer Name
                    </th>
                    <th class="text-center" style="width:25px">
                       <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >SIO</span>
                            </template>
                            Stock in offer
                       </v-tooltip>
                    </th>
                    <th class="text-center" style="width:25px">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >SIW</span>
                            </template>
                            Stock in warehouse
                       </v-tooltip>
                    </th>
                    <th class="text-center" style="width:50px">
                        <v-tooltip bottom>
                           <template v-slot:activator="{ on, attrs }">
                                <span
                                v-bind="attrs"
                                v-on="on"
                                >PIS</span>
                            </template>
                            Parts in stock
                       </v-tooltip>
                    </th>
                    <th class="text-left">
                        Price
                    </th>
                    <th class="text-left">
                        Warehouse ids
                    </th>
                    <th class="text-left">
                        Parts in stock and Ids
                    </th>
                    <th class="text-left" style="width:150px">
                        Note
                    </th>
                    <th v-if="type=='getAllIncorrectOffers'" class="text-center" style="width:35px">
                        Warning
                    </th>
                    <th class="text-right" style="width:30px">
                    </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="offer in offers" :key="offer.id">
                    <td><a :href="`https://allegro.pl/oferta/${offer.allegroId}`" target="_blank">{{ offer.allegroId }}</a></td>
                    <td>{{ offer.name }}</td>
                    <td class="text-center">{{ offer.availableInOffer }}</td>
                    <td v-if="offer.availableInOffer > offer.availableInStock" class="red lighten-2 text-center">{{ offer.availableInStock }}</td>
                    <td v-if="offer.availableInOffer < offer.availableInStock" class="orange lighten-2 text-center">{{ offer.availableInStock }}</td>
                    <td v-if="offer.availableInOffer == offer.availableInStock" class="green lighten-2 text-center">{{ offer.availableInStock }}</td>
                    <td>
                        <span v-for="partGroup in offer.partGroups" :key="partGroup.id">
                            {{partGroup.availableInStock}}<span v-if="offer.partGroups.length>1">/</span>
                        </span>
                    </td>
                    <td>{{offer.price}} {{offer.currency}}</td>
                    <td style="width:140px"><div v-for="id in offer.warehouseIds" :key="id"><a :href="`https://wfirma.pl/goods/view/${id}`" target="_blank">{{id}}</a></div></td>
                    <td>
                        <div v-for="partGroup in offer.partGroups" :key="partGroup.id">
                            <div v-for="partsId in partGroup.partsId" :key="partsId"> <a :href="`https://wfirma.pl/goods/view/${partsId}`" target="_blank">{{partsId}}</a></div>
                        </div></td>
                    <td>{{offer.note}}</td>   
                    <td v-if="type=='getAllIncorrectOffers'" class="text-center"><v-switch
                                        v-model="offer.warningNotification"
                                        dense
                                        @click="updateWarningNotification(offer.id)"
                                        ></v-switch></td>
                    <td class="text-right">
                        <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                            v-bind="attrs"
                            v-on="on"
                            >
                            mdi-chevron-down
                            </v-icon>
                        </template>
                            <v-list dense>
                                <v-list-item>
                                    <a @click="editNote(offer.id, offer.note)"><v-list-item-title>Edit note</v-list-item-title></a>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>End auction</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Change stock</v-list-item-title>
                                </v-list-item>
                                <v-list-item>
                                    <v-list-item-title>Change price</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </td>
                    </tr>
                </tbody>
                </template>
            </v-simple-table>    
        
            <v-dialog
            v-model="noteModal"
            width="600"
            >
                <v-card style="padding:30px">
                    <h1 class="overline">Edit note</h1>
                    <v-divider></v-divider>
                            <v-textarea
                            solo
                            flat
                            name="input-7-4"
                            v-model="noteContent"
                            ></v-textarea>
                    <v-btn
                        color="primary"
                        text
                        @click="saveNote()"
                    >
                        SAVE NOTE
                    </v-btn>
                </v-card>
            </v-dialog>
        </div>
  </div>
  
</template>


<script>


export default {
    methods: {
        editNote(offerId, offerNote){
            this.noteModal=true; 
            this.noteContent=offerNote; 
            this.noteOfferId=offerId;
        },
        saveNoteLocal(){
            this.offers.forEach(i=>{
                if(i.id==this.noteOfferId){
                    i.note=this.noteContent;
                }
            })
        },
        saveNote(){
            this.$store.dispatch("updateNote", {
                offerId: this.noteOfferId,
                noteContent: this.noteContent,
            })
                    .then((response) => {
                    console.log(response);
                    this.noteModal=false;
                    this.saveNoteLocal();
                    this.$store.dispatch("snackbar/setSnackbar", {
                        color: "success",
                        text: "Note updated",
                    });
                    })
                    .catch((error) => {
                    console.log(error);
                    this.$store.dispatch("snackbar/setSnackbar", {
                        color: "error",
                        text: "Something went wrong with saveNote()",
                    });
                });
        },
        updateWarningNotification(offerId){
            this.$store.dispatch("updateWarningNotification", {
                offerId: offerId,
            })
                    .then((response) => {
                    console.log(response);
                    this.saveNoteLocal();
                    this.$store.dispatch("snackbar/setSnackbar", {
                        color: "success",
                        text: "Warning notification changed",
                    });
                    })
                    .catch((error) => {
                    console.log(error);
                    this.$store.dispatch("snackbar/setSnackbar", {
                        color: "error",
                        text: "Something went wrong with changeWarningNotification()",
                    });
                });
        },
        getAllOffers(type) {
                this.offersReady = false;
                this.dataRequested = true;
                this.$store.dispatch(type)
                    .then((response) => {
                        this.offers = response.data;
                        this.offersReady = true;
                    })
                    .catch((error) => {
                    console.log(error)
                    this.$store.dispatch("snackbar/setSnackbar", {
                        color: "error",
                        text: "Something went wrong with getAllOffers()",
                    });
                });
        },    
    },
    mounted() {
        this.getAllOffers(this.type);
    },
    watch: {
        type: function (val) {
            this.getAllOffers(val);
        }
    },
    props: ['type'],
    data() {
        return {
            noteOfferId: null,
            noteContent: "",
            noteModal: false,
            offers: null,
            offersReady: false,
            dataRequested: false
        }
  }
};
</script>

