<template>
    <div class="my-2">
      <order-modal v-model="showOrderModal" :item="this.orderData"/>
      <shipping-status-modal v-model="showShippingStatusModal" :data="this.shipmentStatusData" :trackingNumber="this.statusTrackingNumber"/>
      <h1 class="overline">Przesyłki</h1>
      <v-divider/>
        <v-row>
          <v-col>
          <div v-if="data.content.length==0"><h1>BRAK DANYCH</h1></div>
          <v-simple-table v-if="data.content.length>0" dense>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">
                      ID
                    </th>
                    <th class="text-left">
                      Firma kurierska
                    </th>
                    <th class="text-left">
                      Numer monitorowania
                    </th>
                    <th class="text-left">
                      Data utworzenia
                    </th>
                    <th class="text-right">
                      Akcje
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in data.content" :key="item.id">
                    <td>{{ item.id }}</td>
                    <td>{{ item.shippingProvider }}</td>
                    <td>{{ item.trackingNumber }}</td>
                    <td>{{ item.createdAt }}</td>
                    <td class="text-right">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon v-on:click="getShipmentStatus(item.trackingNumber, item.shippingProvider)">mdi-magnify</v-icon>
                          </span>
                        </template>
                        <span>Pokaż status przesyłki</span>
                      </v-tooltip>   
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon v-on:click="getLabel(item.id, item.shippingProvider)">mdi-file-download-outline</v-icon>
                          </span>
                        </template>
                        <span>Pobierz etykietę przesyłki</span>
                      </v-tooltip>                     
                      <v-tooltip bottom v-if="item.orderId!=null">
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon @click="showOrder(item.orderId)">mdi-text-box-search-outline</v-icon>
                          </span>
                        </template>
                        <span>Pokaż powiązane zamówienie</span>
                      </v-tooltip>   
                      <v-icon v-if="item.orderId==null" disabled>mdi-text-box-search-outline</v-icon>   
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <v-icon @click="removeShipment(item.trackingNumber, item.shippingProvider)">mdi-delete-outline</v-icon>
                          </span>
                        </template>
                        <span>Usuń przesyłkę</span>
                      </v-tooltip>   
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row align="center" v-if='totalPages > 1' justify="center">
            <v-pagination v-model="currentPage" :length="totalPages" :total-visible="3"></v-pagination>
        </v-row>
    </div>
</template>

<script>

import OrderModal from '../components/OrderModal.vue';
import ShippingStatusModal from '../components/ShippingStatusModal.vue';

export default {
  name: "Shipments",
  components: { OrderModal, ShippingStatusModal },
  mounted() {
    this.getShipments(1);
  },
  methods: {
    removeShipment(trackingNumber, shippingProvider){
      if(confirm("You are about to remove shipment no. "+trackingNumber+" provided by "+shippingProvider+". Are you sure?")){
        this.$store.dispatch("removeShipment", { trackingNumber: trackingNumber, shippingProvider: shippingProvider })
        .then((response) => {
            if(response.data == true){
              this.$store.dispatch("snackbar/setSnackbar", {
              color: "success",
              text: "Succesfully removed shipment "+trackingNumber,
              });
            } else {
              this.$store.dispatch("snackbar/setSnackbar", {
              color: "error",
              text: "Shipment removal failed",
              });
            }
        })
        .catch((error) => {
            console.log(error)
            this.$store.dispatch("snackbar/setSnackbar", {
            color: "error",
            text: "Something went wrong with removeShipment()",
            });
        });
      }
    },
    showOrder(id){
      this.$store.dispatch("getOrder", { id: id })
      .then((response) => {
          this.orderData = response.data;
          this.showOrderModal = true;
      })
      .catch((error) => {
          console.log(error)
          this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Something went wrong with getLabel()",
          });
      });
    },  
    getLabel(id, provider){
    this.$store
      .dispatch("getLabel", { id: id })
      .then((response) => {
        if(provider=="DHL"){
            window.open("data:application/pdf;base64, " + response.data);
          } else if(provider=="UPS"){
            window.open("data:image/gif;base64, " + response.data)
          }
      })
      .catch((error) => {
        console.log(error)
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Something went wrong with getLabel()",
        });
      });        
    },
    getShipments(page){
      let calculatedOffset = this.itemsPerPage * (page - 1);
      this.$store.dispatch("getShipments", { limit: this.itemsPerPage, offset: calculatedOffset})
        .then((response) => {
            this.data = response.data;
            this.totalItems = response.data.totalElements;
            this.totalPages = response.data.totalPages;
        });
    },

    getShipmentStatus(trackingNumber, shippingProvider){
        this.$store
            .dispatch("getShipmentStatus", { trackingNumber: trackingNumber, shippingProvider: shippingProvider })
            .then((response) => {
                this.shipmentStatusData = response.data;
                this.statusTrackingNumber = trackingNumber;
                this.showShippingStatusModal = true;
            })
        .catch((error) => {
            console.log(error)
            this.$store.dispatch("snackbar/setSnackbar", {
                color: "error",
                text: "Something went wrong with getShipmentStatus()",
            });
        });        
    },
  },
  data() {
    return {
      data: null,
      orderData: null,
      showOrderModal: false,
      showShippingStatusModal: false,
      shipmentStatusData: null,
      statusTrackingNumber: null,
      itemsPerPage: 20,
      page: 1,
      totalPages: 1,
    }
  },
  computed: {
    currentPage: {
      totalPages(){
        return this.content.totalPages;
      },
      get() {
        return parseInt(this.$route.query.page || 1)
      },
      set(newPage){
        this.$router.push({query: { ...this.$route.query, page: newPage}})
        .catch(() => {});
        this.getShipments(newPage);
      },
    }
  },
};
</script>
