<template>
  <div class="users">
    <div class="my-2">
      <h1 class="overline">Users</h1>
      <v-divider/>
    </div>
    <v-row>
      <v-col>
        <v-simple-table v-if="data!=null" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">
                    Imię
                  </th>
                  <th class="text-left">
                    Nazwisko
                  </th>
                  <th class="text-left">
                    Adres email
                  </th>
                  <th class="text-left">
                    Numer telefonu
                  </th>
                  <th class="text-left">
                    Stanowisko
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in data" :key="item.id">
                  <td>{{ item.name }}</td>
                  <td>{{ item.surname }}</td>
                  <td>{{ item.email }}</td>
                  <td>{{ item.phone }}</td>
                  <td>{{ item.businessTitle }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
      </v-col>
    </v-row>
    <v-row cols="12">
      <v-col sm="6" md="6" lg="6">
        <v-row class="pa-2">
          <v-col>
            <div class="my-2">
              <h1 class="overline">Dodaj użytkownika</h1>
              <v-divider/>
            </div>
             <v-text-field dense outlined v-model="newUser.username" label="Nazwa użytkownika"></v-text-field>
             <v-text-field dense outlined v-model="newUser.email" label="Email"></v-text-field>
             <v-text-field dense outlined v-model="newUser.name" label="Imię"></v-text-field>
             <v-text-field dense outlined v-model="newUser.surname" label="Nazwisko"></v-text-field>
             <v-text-field dense outlined v-model="newUser.password" label="Hasło"></v-text-field>
             <v-text-field dense outlined v-model="newUser.phone" label="Numer telefonu"></v-text-field>
             <v-text-field dense outlined v-model="newUser.businessTitle" label="Stanowisko"></v-text-field>
             <v-btn
                  depressed
                  outlined
                  x-large
                  height="39.5px"
                  color="rgba(0, 0, 0, 0.6)"
                  @click="addUser()"
                  >Dodaj użytkownika</v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="6" md="6" lg="6">
        <v-row class="pa-2">
          <v-col v-if="data!=null">
            <div class="my-2">
              <h1 class="overline">Edytuj użytkownika</h1>
              <v-divider/>
            </div>
            <v-autocomplete
            label="Wybierz użytkownika do edycji"
            dense
            depressed
            outlined 
            :items="data"
            item-text="email"
            return-object
            @input="setEditedUser($event)">
            </v-autocomplete>
            <div v-if="editedUser!=null">
            <v-text-field dense outlined label="Email" v-model="editedUser.email"></v-text-field>
            <v-text-field dense outlined label="Imię" v-model="editedUser.name"></v-text-field>
            <v-text-field dense outlined label="Nazwisko" v-model="editedUser.surname"></v-text-field>
            <v-text-field dense outlined label="Numer telefonu" v-model="editedUser.phone"></v-text-field>
            <v-text-field dense outlined label="Stanowisko" v-model="editedUser.businessTitle"></v-text-field>
            <v-btn
                  depressed
                  outlined
                  x-large
                  height="39.5px"
                  color="rgba(0, 0, 0, 0.6)"
                  @click="editUser()"
                  >Zapisz użytkownika</v-btn>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row cols="12">
      <v-col sm="6" md="6" lg="6">
        <v-row class="pa-2">
          <v-col v-if="data!=null">
              <div class="my-2">
                <h1 class="overline">Usuń użytkownika</h1>
                <v-divider/>
              </div>
              <v-autocomplete
                label="Wybierz użytkownika do usunięcia"
                dense
                depressed
                outlined 
                :items="data"
                item-text="email"
                return-object
                @input="setRemoveUser($event)">
              </v-autocomplete>
              <div v-if="deleteUserId!=null">
              <v-btn
                    depressed
                    outlined
                    x-large
                    height="39.5px"
                    color="rgba(0, 0, 0, 0.6)"
                    @click="deleteUser()"
                    >Usuń użytkownika</v-btn>
              </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  name: 'Users',
    methods: {
    setRemoveUser(user){
      this.deleteUserId = user.id;
    },

    setEditedUser(user){
      this.editedUser = user;
    },

    getUsers(){
      this.$store.dispatch("getUsers")
        .then((response) => {
            this.data = response.data;
        });
    },

    editUser(){
      this.$store.dispatch("editUser", { id: this.editedUser.id,
                                      body: {
                                        name: this.editedUser.name,
                                        surname: this.editedUser.surname,
                                        email: this.editedUser.email,
                                        phone: this.editedUser.phone,
                                        businessTitle: this.editedUser.businessTitle,
                                      } })
      .then((response) => {
        if(response.data==true) { 
          this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Edytowano użytkownika" })
        } else {
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Nie udało się edytować użytkownika" })
        }
        this.getUsers();
      })
      .catch((error) => {
        if(error.response.status==403) this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Brak uprawnień"});
        this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Niepowodzenie podczas przetwarzania żądania: " + error});
      });        
    },

    addUser(){
      this.$store.dispatch("addUser", { body: this.newUser })
      .then((response) => {
        console.log(response)
        if(response.data==true) { 
          this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Dodano użytkownika" })
        } else {
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Nie udało się dodać użytkownika" })
        }
        this.getUsers();
      })
      .catch((error) => {
        if(error.response.status==403) this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Brak uprawnień"});
        this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Niepowodzenie podczas przetwarzania żądania: " + error});
      });        
    },

    deleteUser(){
      this.$store.dispatch("deleteUser", { id: this.deleteUserId })
      .then((response) => {
        if(response.data==true) { 
          this.$store.dispatch("snackbar/setSnackbar", { color: "success", text: "Usunięto użytkownika" })
        } else {
          this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Nie udało się usunąć użytkownika" })
        }
        this.getUsers();
      })
      .catch((error) => {
        if(error.response.status==403) this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Brak uprawnień"});
        this.$store.dispatch("snackbar/setSnackbar", { color: "error", text: "Niepowodzenie podczas przetwarzania żądania: " + error});
      });        
    },

  },
  data() {
    return {
      data: null,
      editedUser: null,
      newUser: {},
      deleteUserId: null,
    }
  },
  mounted() {
    this.getUsers()
  },
}
</script>
