<template>
    <div class="allegro">
        <div>
            <v-alert v-if="allegroAuth==false" border="top" color="red lighten-2" dark>RWCentral obecnie nie jest autoryzowany przez Allegro API.
                <div style="margin-top:10px;">
                <v-btn
                outlined
                color="white"
                @click="authenticateAllegro()"
                >
                Kliknij tutaj aby autoryzować
                </v-btn>
                </div>
            </v-alert>
            <v-alert v-if="showVerification==true && allegroAuth==false" border="top" color="orange lighten-2" dark>Kliknij poniższy przycisk aby autoryzować RWCentral.
                <div style="margin-top:10px;">
                <v-btn
                outlined
                color="white"
                >
                <a :href="this.verificationUri" target="_blank" style="color: white; text-decoration: none;">Autoryzuj w allegro</a>
                </v-btn>
                </div>
            </v-alert>
            <v-alert v-if="allegroAuth==true" border="top" color="green lighten-2" dark>RWCentral jest autoryzowany przez Allegro API.
                <div style="margin-top:10px;">
                </div>
            </v-alert>
        </div>
        <div>
            <v-tabs
            color="#9e9e9e"
            background-color="#f5f5f5">
                <v-tab @click="view='incorrect'">
                    Niepoprawne
                </v-tab>
                <v-tab @click="view='semicorrect'">
                    Akceptowalne
                </v-tab>
                <v-tab @click="view='correct'">
                    Poprawne
                </v-tab>
                <v-tab @click="view='ended'">
                    Zakończone
                </v-tab>
            </v-tabs>

            <div v-if="view == 'incorrect'">
                <offer-table type="getAllIncorrectOffers"/>
            </div>
            <div v-if="view == 'semicorrect'">
                <offer-table type="getAllSemicorrectOffers"/>
            </div>
            <div v-if="view == 'correct'">
                <offer-table type="getAllCorrectOffers"/>
            </div>
            <div v-if="view == 'ended'">
                <offer-table type="getAllEndedOffers"/>
            </div>
        </div>
  </div>
</template>

<script>

import OfferTable from '../components/allegroTables/OfferTable.vue';

export default {
    components: { OfferTable },
    methods: {
        isAllegroAuthenticated() {
            this.$store
                .dispatch("getIsAllegroAuthenticated")
                .then((response) => {
                    this.allegroAuth=response.data;
                })
                .catch((error) => {
                console.log(error)
                this.$store.dispatch("snackbar/setSnackbar", {
                    color: "error",
                    text: "Something went wrong with isAllegroAuthenticated()",
                });
            });
        },
        authenticateAllegro() {
            this.$store
                .dispatch("authenticateAllegro")
                .then((response) => {
                    this.verificationUri=response.data.authUri;
                    this.userCode=response.data.userCode;
                    this.showVerification=true;
                })
                .catch((error) => {
                console.log(error)
                this.$store.dispatch("snackbar/setSnackbar", {
                    color: "error",
                    text: "Something went wrong with authenticateAllegro()",
                });
            });
        },
    },
    mounted() {
        this.isAllegroAuthenticated();
    },
    data() {
    return {
        view: 'offers',
        showVerification: false,
        allegroAuth: null,
        verificationUri: null,
        userCode: null,
        offers: null,
        offersReady: false,
        dataRequested: false
    }
  }

};
</script>
