<template>
    <v-container class="mx-3 mt-2">
        <price-chart v-if="dataReady=true" :x="this.content[0]" :y="this.content[2]" :unit="this.currency"/>
        <price-chart v-if="dataReady=true" :x="this.content[0]" :y="this.content[1]" unit="szt."/>
    </v-container>
</template>

<script>
import PriceChart from './charts/PriceChart.vue';

export default {
    methods: {
        getProduct() {
            this.$store.dispatch("getProductById", {
                id: this.id,
            })
            .then((response) => {
                this.currency =  response.data.currency;
                this.getProductHistoryForChart(response.data.provider, response.data.vendorId);
            })
            .catch((error) => {
                console.log(error);
                this.$store.dispatch("snackbar/setSnackbar", {
                    color: "error",
                    text: "Something went wrong with getProduct()",
                });
            });
        },
        getProductHistoryForChart(provider, vendorId) {
            this.$store.dispatch("getProductHistoryForChart", {
                vendorId: vendorId,
                provider: provider
            })
            .then((response) => {
                this.content = response.data;
                this.dataReady = true;
            })
            .catch((error) => {
                console.log(error);
                this.$store.dispatch("snackbar/setSnackbar", {
                    color: "error",
                    text: "Something went wrong with getProductHistoryForChart()",
                });
            });
        },
    },
    components: { PriceChart },
    name: 'ProductHistoryPrice.vue',
    props: ["productData"],
    mounted() {
        this.getProduct();
    },
    data() {
        return {
            id: this.$route.params.id,
            currency: null,
            content: null,
            dataReady: false,
        }
    },
}
</script>
