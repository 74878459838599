<template>

    <v-dialog v-model="show" width="1400px">
        <v-card class="pa-4">
            <div class="overline mb-2" style="font-size:15px !important;">
            {{item.orderSource}} order no. {{item.externalOrderNumber}}
            <v-divider></v-divider>
            </div>
                      <v-row no-gutters style="background-color:white">
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dane do wysyłki:</h1>
                          <div class="caption" v-if="item.shippingAddress!=null">
                              <div v-if="item.deliveryMethod!='PICKUP_POINT'">{{item.shippingAddress.companyName}}</div>
                              <div v-if="item.deliveryMethod=='PICKUP_POINT'">{{item.shippingAddress.optionalPickupPointName}}</div>
                              <div>{{item.shippingAddress.street}}</div>
                              <div>{{item.shippingAddress.postalCode}} {{item.shippingAddress.city}}</div>
                              <div>{{item.shippingAddress.contactPerson}}</div>
                              <div>{{item.shippingAddress.phoneNumber}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dane do faktury:</h1>
                          <div class="caption" v-if="item.billingAddress!=null">
                            <div v-if="item.billingAddress.taxNumber==null">{{item.billingAddress.contactPerson}}</div>
                              <div>{{item.billingAddress.companyName}}</div>
                              <div>{{item.billingAddress.street}}</div>
                              <div>{{item.billingAddress.postalCode}} {{item.billingAddress.city}}</div>
                              <div v-if="item.billingAddress.taxNumber!=null">NIP: {{item.billingAddress.taxNumber}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Informacje o kupującym</h1>
                          <div class="caption">
                              <div v-if="item.shippingAddress!=null">{{item.shippingAddress.contactPerson}}</div>
                              <div>{{item.email}}</div>
                          </div>
                      </v-col>
                      <v-col cols="12" sm="3">
                          <h1 class="overline">Dodatkowe informacje:</h1>
                          <div class="caption">
                              <div v-if="item.wfirmaReservationNumber!=null">Rezerwacja: <a target="_blank" :href="'https://wfirma.pl/warehouse_documents/view/'+item.wfirmaReservationId">{{item.wfirmaReservationNumber}}</a></div>
                              <div>Data zamówienia: {{item.orderDatetime}}</div>
                              <div v-bind:style= "[item.paymentStatus=='UNPAID' ? {'color':'red'} : {'color':'black'}]">Status płatności: {{item.paymentStatus}}</div>
                              <div>Typ płatności: {{item.paymentType}}</div>
                              <div>Typ dostawy: {{item.deliveryMethod}}</div>
                              <div v-if="item.clientMessage!=''"><b>Wiadomość od kupującego:</b></div>
                              <div v-if="item.clientMessage!=''">{{item.clientMessage}}</div>
                          </div>
                      </v-col>
                  </v-row>
              


                  <h1 class="overline mt-4">Produkty w zamówieniu:</h1>
                  <v-row no-gutters class="mb-4">
                      <v-col cols="12" sm="12">
                      <v-simple-table dense>
                          <template v-slot:default>
                          <thead>
                              <tr>
                              <th class="text-left">
                                  Nazwa produktu
                              </th>
                              <th class="text-left">
                                  WFID
                              </th>
                              <th class="text-left">
                                  Cena
                              </th>
                              <th class="text-left">
                                  Ilość
                              </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr v-for="position in item.positions" v-bind:key="position.id">
                              <td>{{position.productName}}</td>
                              <td><a target="_blank" :href="'https://wfirma.pl/goods/view/'+position.warehouseCode">{{position.warehouseCode}}</a></td>
                              <td>{{position.unitPrice}} PLN</td>
                              <td>{{position.units}} szt.</td>
                              </tr>
                          </tbody>
                          </template>
                      </v-simple-table>
                      </v-col>
                  </v-row>


                <h1 class="overline mt-6" v-if="item.shippingInfo.length!=0">Przesyłki:</h1>
                  <v-row no-gutters class="mb-4" v-if="item.shippingInfo.length!=0">
                      <v-col cols="12" sm="12">
                        <a v-on:click="getLabel(shipping.id)" v-for="shipping in item.shippingInfo" v-bind:key="shipping.id">
                          {{shipping.shippingProvider}} {{shipping.trackingNumber}}
                        </a>
                      </v-col>
                  </v-row>
                  
                  <v-row no-gutters>
                      <v-col cols="12" sm="2" class="text-left">
                          <h1 class="overline mt-4">Notatka wewnętrzna:</h1>
                          <div class="caption">{{item.internalNote}}</div>
                      </v-col>
                      <v-col cols="12" sm="8"></v-col>
                      <v-col cols="12" sm="2" class="text-right">
                          <h1 class="overline mt-4">Wartość zamówienia:</h1>
                          <div class="caption">Wartość: {{item.orderValue-item.shippingCost}} PLN</div>
                          <div class="caption">Koszt wysyłki: {{item.shippingCost}} PLN</div>
                          <div class="caption">Łączna wartość: {{item.orderValue}} PLN</div>
                      </v-col>
                  </v-row>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
  methods: {
      getLabel(id){
      this.$store
      .dispatch("getLabel", { id: id })
      .then((response) => {
        window.open("data:application/pdf;base64, " + response.data);
      })
      .catch((error) => {
        console.log(error)
        this.$store.dispatch("snackbar/setSnackbar", {
          color: "error",
          text: "Something went wrong with getLabel()",
        });
      });        
    },
  },
  props: {
    value: Boolean,
    item: null,
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  }
};
</script>